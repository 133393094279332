import axios from "axios";
import authHeader from "./auth.header";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://payment.t7d.io"
} else {
    // BASE_URL = "http://localhost:3008"
    BASE_URL = "https://staging.payment.t7d.io"
}

//Orders paginated
const getAllOrders = async (pageNumber, payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/orders/getOrdersForAdmin?page=${pageNumber}`,
        data: payload,
        headers: authHeader()
    });
}

const resendVoucher = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/resend/resendVoucher`,
        data: payload,
        headers: authHeader()
    });
}

const reBuyVoucher = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/resend/reBuyVoucher`,
        data: payload,
        headers: authHeader()
    });
}



// Statistics
const getTotal = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/statistics/getTotal`,
        data: payload,
        headers: authHeader()
    });
}
const getNumberOfSells = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/statistics/getNumberOfSells`,
        data: payload,
        headers: authHeader()
    });
}

const getTotalPurchases = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/statistics/getTotalPurchases`,
        data: payload,
        headers: authHeader()
    });
}
const getNumberAndTotalOfPurchasesByGateway = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/statistics/getNumberAndTotalOfPurchasesByGateway`,
        data: payload,
        headers: authHeader()
    });
}

const getAmountOfGdGenerated = async (payload) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/statistics/getAmountOfGdGenerated`,
        headers: authHeader()
    });
}

const getSuccessPurchasesOfTheWeek = async (payload) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/orders/getSuccessPurchasesOfTheWeek`,
        headers: authHeader()
    });
}
const getTotalGDGiftCardsSold = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/statistics/getGDGiftCardsSold`,
        data: payload,
        headers: authHeader()
    });
}

const getAlaanTotalPayment = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/statistics/getAlaanTotalPayment`,
        data: payload,
        headers: authHeader()
    });
}

// GD
const addGD = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/gd/addGd`,
        data: payload,
        headers: authHeader()
    });
}
const getProfilesGdHistory = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/gd/getGdHistoryFiltered`,
        data: payload,
        headers: authHeader()
    });
}

const getGdInHistory = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/gd/gdInHistory/?skip=${payload.skip}&limit=${payload.limit}`,
        data: payload,
        headers: authHeader()
    });
}

const getGdOutHistory = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/gd/gdOutHistory/?skip=${payload.skip}&limit=${payload.limit}`,
        data: payload,
        headers: authHeader()
    });
}

const getStatus = async () => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/gd/gdstatus`,
        headers: authHeader()
    });
}

//redeemCodes
const getRedeemCodes = async (playload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/gd/t7dgetredeemcode`,
        data: playload,
        headers: authHeader()
    });
}
export default {
    getAllOrders, resendVoucher, reBuyVoucher,
    getTotal, getTotalPurchases, getNumberOfSells, getNumberAndTotalOfPurchasesByGateway,
    getAmountOfGdGenerated, getSuccessPurchasesOfTheWeek, getTotalGDGiftCardsSold, getAlaanTotalPayment,
    addGD, getProfilesGdHistory, getGdInHistory, getGdOutHistory, getStatus,
    getRedeemCodes
}

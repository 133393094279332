import React from 'react';
import moment from 'moment';
import { EditIcon, DeleteIcon, DuplicateIcon, Pro, Solo, Premium, Team, Free, RedLike, Check, GreyClockIcon, ClockIcon, CalendarIcon, Trophy, MenaFlag, RamadanIcon, ThinkingIcon, SummerIcon } from '../assets/img';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import CreateUpdateTournament from '../components/Tournament/CreateUpdateTournament';
import tournamentService from 'services/tournament.service';
import styled from 'styled-components';
import Notification from 'utils/notification';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Loader from 'components/UI/Loader';
import { decrementPageNumber } from 'redux/reducers/tournamentSlice';
import { incrementPageNumber } from 'redux/reducers/tournamentSlice';
import NoData from 'components/UI/NoData';
import PaginationBox from 'components/UI/PaginationBox';
import { fetchTournaments } from 'redux/reducers/tournamentSlice';
import { DateRangePicker } from 'rsuite';

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .cancel{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
    .duplicate{
        cursor: pointer;
    }
`
const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
`
const SortFilterDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: fit-content;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
`
function Tournaments() {
    const navigate = useNavigate();
    const [tournament, setTournament] = React.useState()
    const [tournamentId, setTournamentId] = React.useState()
    const [playersType, setPlayersType] = React.useState("All");
    const [status, setStatus] = React.useState("All");
    const [format, setFormat] = React.useState("All");
    const [game, setGame] = React.useState("All");
    const [games, setGames] = React.useState([]);
    const [dates, setDates] = React.useState(undefined);
    const [isEsports, setIsEsports] = React.useState(false);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    // Modal open state
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const dispatch = useDispatch();
    const types = [
        { key: "0", value: "All" },
        { key: "1", value: "Free" },
        { key: "2", value: "Pro" },
        { key: "3", value: "Premium" }
    ]
    const allStatus = [
        { key: "0", value: "All" },
        { key: "1", value: "Upcoming" },
        { key: "2", value: "Active" },
        { key: "3", value: "Past" }
    ]
    const allFormats = [
        { key: "0", value: "All" },
        { key: "1", value: "Groups" },
        { key: "2", value: "Brackets" },
        { key: "3", value: "Single Elimination" }
    ]

    const { loading, tournaments, pageNumber } = useSelector((state) => state.tournamentSlice, shallowEqual)
    const { afterToday } = DateRangePicker;
    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }

    // Toggle for Modal
    const toggle = (t) => {
        if (t !== undefined) {
            setTournament(t)
        } else {
            setTournament(undefined)
        }
        getAllTournaments(playersType, status, format, game, dates);
        setModal(!modal)
    }
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }
    const navigateDetails = (id) => {
        navigate(id);
    };
    React.useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('profile'));
        getAllTournaments(playersType, status, format, game, dates);
        if (profile) {
            setIsEsports((profile.roleId.title).includes('e-sports'));
        }
    }, [pageNumber])

    React.useEffect(() => {
        getGames();
    }, [])

    const getGames = async () => {
        tournamentService.getTournamentGames().then(response => {
            setGames(response.data.data)
        })
    }

    const getAllTournaments = (updatedType, updatedStatus, updatedFormat, updatedGame, updatedDates) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        let filter = { pageNumber, skip, limit }
        if (updatedType !== undefined && updatedType !== "All") filter.playersType = updatedType
        if (updatedStatus !== undefined && updatedStatus !== "All") filter.status = updatedStatus
        if (updatedFormat !== undefined && updatedFormat !== "All") filter.format = updatedFormat
        if (updatedGame !== undefined && updatedGame !== "All") filter.gameIds = [updatedGame]
        if (updatedDates !== undefined && updatedDates !== null) {
            filter.startDate = updatedDates[0];
            filter.endDate = updatedDates[1];
        }
        if (updatedType !== playersType || updatedStatus !== status || updatedFormat !== format || updatedGame !== game || updatedDates !== dates) {
            for (let i = pageNumber; i > 1; i--) {
                decrementPage()
            }
        }
        dispatch(fetchTournaments(filter))
    }

    const deleteTournament = async (id) => {
        try {
            tournamentService.deleteOneTournament(id).then((response) => {
                setTournamentId("");
                deleteToggle();
                getAllTournaments(playersType, status, format, game, dates);
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    const duplicateTournament = async (tournament) => {
        try {
            const { companyId, creatorId, enabledNotification, nbParticipation, updatedAt, createdAt, _id, __v, brackets, ...newTournament } = tournament;

            tournamentService.duplicate(newTournament).then((response) => {
                getAllTournaments(playersType, status, format, game, dates);
                setColor("2");
                setMessage("Tournament duplicated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 2000);
            }).catch((error) => {
                if (error.response.status === 400) {
                    setColor("3");
                    setMessage(error.response.data.data.errorMessage);
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                    }, 4000);
                }
                console.log(error.message);
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    const createTournamentStyle = {
        fontSize: "1em",
        cursor: "pointer"
    }

    const flagStyle = {
        width: "40px",
        height: "40px"
    }

    return (
        <>
            <div className="content">
                {showNotif && <Notification color={color} message={message} />}
                <Row>
                    <Col md="12">
                        <Card style={{
                            height: '730px',
                            overflowY: 'auto'
                        }}>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={Trophy} alt="trophy" style={{ marginRight: "8px" }} /> Tournaments</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SortFilterDiv>
                                            <h5>Type: </h5>
                                            <select defaultValue="All" onChange={(e) => { setPlayersType(e.target.value); getAllTournaments(e.target.value, status, format, game, dates); }} name="playersType">
                                                {types.map((type) => {
                                                    return (
                                                        <option key={type.key} value={type.value}>{type.value}</option>
                                                    );
                                                })}
                                            </select>
                                            <h5>Status: </h5>
                                            <select defaultValue="All" onChange={(e) => { setStatus(e.target.value); getAllTournaments(playersType, e.target.value, format, game, dates); }} name="status">
                                                {allStatus.map((status) => {
                                                    return (
                                                        <option key={status.key} value={status.value}>{status.value}</option>
                                                    );
                                                })}
                                            </select>
                                            <h5>Format: </h5>
                                            <select defaultValue="All" onChange={(e) => { setFormat(e.target.value); getAllTournaments(playersType, status, e.target.value, game, dates); }} name="format">
                                                {allFormats.map((format) => {
                                                    return (
                                                        <option key={format.key} value={format.value}>{format.value}</option>
                                                    );
                                                })}
                                            </select>
                                            <h5>Games: </h5>
                                            <select defaultValue="All" onChange={(e) => { setGame(e.target.value); getAllTournaments(playersType, status, format, e.target.value, dates); }} name="format">
                                                <option value="All">All games</option>
                                                {games.map((g) => {
                                                    return (
                                                        <option key={g._id} value={g._id}>{g.title}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                        <DateRangePicker showOneCalendar
                                            size="sm"
                                            placeholder="Select Date Range"
                                            disabledDate={afterToday()}
                                            placement='bottomEnd'
                                            onChange={(date) => { setDates(date); getAllTournaments(playersType, status, format, game, date); }}
                                            onClean={() => setDates(undefined)}
                                        />
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {modal && <CreateUpdateTournament modal={modal} toggle={toggle} tournament={tournament} />}
                                {/* <Filters /> */}
                                {/* <CardText style={{ textAlign: "right", cursor: "pointer" }} onClick={() => { toggle() }}><img src={AddIcon} /> Create tournament</CardText> */}
                                <div className="table-full-width table-responsive" >
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (tournaments.data && tournaments.data.length === 0) ?
                                            <NoData text={"No tournaments!"} /> :
                                            <Table>
                                                {/* <thead className="text-primary" style={{display: "table", width: "calc(100% - 17px)"}}> */}
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Tournament title</th>
                                                        <th>Tournament format</th>
                                                        <th>Tournament start</th>
                                                        <th>Registration phase</th>
                                                        <th className="text-center">Region / Country</th>
                                                        <th className="text-center">Capacity</th>
                                                        <th className="text-center">Prize pool</th>
                                                        <th className="text-center">State</th>
                                                        <th className="text-center">Action</th>
                                                        {/* <th className="text-center">Salary</th> */}
                                                    </tr>
                                                </thead>
                                                {/* <tbody style={{display: "block", maxHeight: '600px', overflowY: 'auto'}}> */}
                                                <tbody>
                                                    {tournaments.data && tournaments.data.map((t, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center" style={{ cursor: "pointer" }} onClick={() => { navigateDetails(t._id) }}>
                                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                        {/* Image */}
                                                                        <div style={{ borderTopLeftRadius: "7px", borderBottomLeftRadius: "7px", height: "80px", width: "50px", backgroundImage: `url(${t.cover})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                                                                        {/* End image */}
                                                                        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                                            {t.title} <br />
                                                                            <div style={{ display: "flex", gap: "5px" }}>
                                                                                {t.playersType.name === "Free" ? <img width="73px" height="22px" alt="free" src={Free} /> :
                                                                                    t.playersType.name === "Pro" ? <img width="58px" height="23px" alt="pro" src={Pro} /> :
                                                                                        <img width="22px" height="23px" alt="premium" src={Premium} />}
                                                                                {t.tournamentType === "Solo" ? <img width="56.6px" height="22px" alt="solo" src={Solo} /> : <img width="63.6px" height="23px" alt="team" src={Team} />}
                                                                                {t.tournamentEvent === "Ramadan" && <img width="25px" height="25px" alt="ramadan" src={RamadanIcon} />}
                                                                                {t.tournamentEvent === "Summer" && <img width="25px" height="25px" alt="summer" src={SummerIcon} />}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{t.tournamentFormat}</td>
                                                                <td>
                                                                    <img src={CalendarIcon} alt="calendar" /> {moment.utc(t.scheduledStartDateTime).format('ll')} {moment.utc(t.scheduledStartDateTime).format('LT')}
                                                                    <br />
                                                                    <img src={GreyClockIcon} alt="clock" style={{ marginRight: "3px" }} /> {moment.utc(t.scheduledEndDateTime).format('ll')} {moment.utc(t.scheduledEndDateTime).format('LT')}
                                                                </td>
                                                                <td>
                                                                    <img src={CalendarIcon} alt="calendar" /> {moment.utc(t.registrationOpeningDateTime).format('ll')} {moment.utc(t.registrationOpeningDateTime).format('LT')}
                                                                    <br />
                                                                    <img src={GreyClockIcon} alt="clock" style={{ marginRight: "3px" }} /> {moment.utc(t.registrationClosingDateTime).format('ll')} {moment.utc(t.registrationClosingDateTime).format('LT')}
                                                                </td>
                                                                <td className="text-center">{t.region === "MENA" ? <img src={MenaFlag} alt="flag" style={flagStyle} /> : t.country}</td>
                                                                <td className="text-center">{t.nbParticipation}/{t.size}</td>
                                                                <td className="text-center">
                                                                    {t.prizeClaim.en}<br />
                                                                    {(t.gdPrize !== undefined && t.gdPrize !== null) && (`${t.gdPrize} GD`)}
                                                                </td>
                                                                <td className="text-center">{Date.parse(t.registrationOpeningDateTime) > new Date().getTime() ? <p style={{ color: "#e57d07" }}><img src={ClockIcon} alt="clock" /> Upcoming</p> :
                                                                    ((Date.parse(t.scheduledStartDateTime) <= new Date().getTime() && Date.parse(t.scheduledEndDateTime) >= new Date().getTime()) || Date.parse(t.scheduledStartDateTime) > new Date().getTime() ?
                                                                        <p style={{ color: "#23e35d" }}><img src={Check} alt="check" /> Active</p> : <p style={{ color: "#fe4958" }}><img src={RedLike} alt="like" /> Completed</p>)}</td>
                                                                <td className="text-center">
                                                                    <ActionStyle>
                                                                        <div className='duplicate'><img src={DuplicateIcon} alt="duplicate" onClick={() => duplicateTournament(t)} /></div>
                                                                        <div className='cancel'><img src={EditIcon} alt="edit" onClick={() => { toggle(t); }} /></div>
                                                                        <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setTournamentId(t._id) }} /></div>
                                                                    </ActionStyle>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox
                            currentPage={pageNumber}
                            numberOfPages={Math.ceil(tournaments.totalNbOfTournaments / limit)}
                            totalNumber={tournaments.totalNbOfTournaments}
                            decrement={decrementPage}
                            increment={incrementPage}
                        />
                    </Col>
                </Row>
                <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                    <ModalHeader toggle={deleteToggle}>Delete Tournament</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this tournament?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => deleteTournament(tournamentId)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
}

export default Tournaments;
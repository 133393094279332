import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'
import { BackArrowIcon, CalendarIcon, DeleteIcon, EditIcon, ThinkingIcon } from '../../assets/img'
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { questions } from 'redux/reducers/quizSlice';
import profileService from 'services/profile.service';
import CreateUpdateQuiz from './CreateUpdateQuiz';
import Loader from 'components/UI/Loader';
import PaginationBox from 'components/UI/PaginationBox';
import { decrementPageNumber } from 'redux/reducers/quizSlice';
import { incrementPageNumber } from 'redux/reducers/quizSlice';
import moment from 'moment';

const Nav = styled.nav`
    border-radius: 14px 14px 0 0;
    background: rgba(117, 123, 168, 0.19);
    height: 85px;
`
const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .cancel{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
    .duplicate{
        cursor: pointer;
    }
`
const NavMenu = styled.div`
    padding: 10px 16px 0 16px;
    button{
        cursor: pointer;
        padding: 16px 50px;
        background: transparent;
        color: white;
        border: none;
        :active{
            border-radius: 8px;
            background-color: #9ca2cf;
        }
    }
`
const NoQuestions = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`

const QuizQuestions = () => {
    const [question, setQuestion] = React.useState()
    const [questionId, setQuestionId] = React.useState()
    const [theme, setTheme] = React.useState()
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    // skip & limit
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);

    const navigate = useNavigate();
    const { themeId } = useParams();

    const { loading, quizQuestions, pageNumber, error } = useSelector((state) => state.quiz, shallowEqual)
    const dispatch = useDispatch();


    const NavigateToThemes = () => {
        navigate("/Quizzes");
    }
    // Toggle for Modal
    const toggle = (question) => {
        if (question !== undefined) {
            setQuestion(question)
        } else {
            setQuestion(undefined)
        }
        dispatch(questions({ themeId, pageNumber, skip }))
        setModal(!modal)
    }
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }
    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }
    const deleteQuestion = async (questionId) => {
        try {
            profileService.deleteQuizQuestion(questionId).then((response) => {
                setQuestionId("");
                deleteToggle();
                dispatch(questions({ themeId, pageNumber, skip }))
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    const fetchQuestions = async () => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        const body = { themeId, pageNumber, skip }
        dispatch(questions(body))
    }

    const getTheme = async () => {
        profileService.getThemeById(themeId).then((response) => {
            setTheme(response.data.data)
        })
    }
    React.useEffect(() => {
        fetchQuestions();
        getTheme();
    }, [])


    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card style={{
                        height: '730px',
                        overflowY: 'auto',
                    }}>
                        <Nav>
                            <NavMenu>
                                <h4><button onClick={() => NavigateToThemes()} >
                                    <img src={BackArrowIcon} />
                                </button>
                                    {theme && theme.title}</h4>
                            </NavMenu>
                        </Nav>
                        <br />

                        <CardBody>
                            <div className="table-full-width table-responsive">
                                {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                    (quizQuestions.data && quizQuestions.data.length === 0) ? <NoQuestions>
                                        <img src={ThinkingIcon} />
                                        <p>No questions for this quiz theme!</p>
                                    </NoQuestions>
                                        :
                                        <Table>
                                            <thead className="text-primary" style={{ position: "sticky" }}>
                                                <tr>
                                                    <th>Question</th>
                                                    <th>From .. To</th>
                                                    <th>Correct response</th>
                                                    <th>GD</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quizQuestions.data && quizQuestions.data.map((question, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td>{question.text}</td>
                                                            <td><img src={CalendarIcon} alt="calendar" /> From <b>{moment(question.startDate).format('ll')}</b> to <b>{moment(question.endDate).format('ll')}</b></td>
                                                            <td>{question.correctResponse}</td>
                                                            <td>{question.gd}</td>
                                                            <td>
                                                                <ActionStyle>
                                                                    <div className='cancel'><img src={EditIcon} alt="edit" onClick={() => { toggle(question); }} /></div>
                                                                    <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setQuestionId(question._id) }} /></div>
                                                                </ActionStyle>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                }
                            </div>
                        </CardBody>
                    </Card>
                    <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(quizQuestions.totalCount / limit)} totalNumber={quizQuestions.totalCount} decrement={decrementPage} increment={incrementPage} />
                </Col>
            </Row>
            <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                <ModalHeader toggle={deleteToggle}>Delete Question</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this question?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => deleteQuestion(questionId)}>Yes</Button>{' '}
                    <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            { modal && <CreateUpdateQuiz modal={modal} toggle={toggle} quiz={question} /> }
        </div >);
}

export default QuizQuestions;
import React from 'react';
import styled from 'styled-components'
import { Row, Col, FormGroup, Form, Input, Button, Tooltip } from "reactstrap";
import { Dialog } from '@reach/dialog';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Notification from '../../utils/notification';
import { useDispatch } from 'react-redux';
import miniGameService from 'services/mimiGames.service';
import { fetchCasualGames } from 'redux/reducers/miniGameSlice';
import { MiniGamesIcon } from 'assets/img';
import { AddAvatarIcon } from 'assets/img';
import { ArrowIcon } from 'assets/img';
import { BackArrowIcon } from 'assets/img';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 100px;
        height: 80px;
        margin-left: 20px;
    }
`

const CreateUpdateMiniGame = ({ modal, toggle, casualGame }) => {
    const [categories, setCategories] = React.useState([]);
    const [gallery, setGallery] = React.useState(undefined);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")
    const [category, setCategory] = React.useState("");
    const [premium, setPremium] = React.useState();
    const [maxPlayers, setMaxPlayers] = React.useState();
    const [isPortrait, setIsPortrait] = React.useState();

    const formData = React.useMemo(() => new FormData(), []);
    const dispatch = useDispatch();

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === 'gallery') {
            formData.set(name, e.target.files[0])
        } else if (name === "categoryId") {
            setCategory(value);
            formData.set(name, value);
        } else if (name === "premium") {
            setPremium(value);
            formData.set(name, value);
        } else if (name === "maxPlayers") {
            setMaxPlayers(value);
            formData.set(name, value);
        } else if (name === "isPortrait") {
            setIsPortrait(value);
            formData.set(name, value);
        } else {
            formData.set(name, value)
        }
    }

    const handleButtonChange = (index) => {
        setTabIndex(index);
    };
    const handleTabsChange = (index) => {
        setTabIndex(index);
    };
    const loadFile = (e) => {
        const [file] = e.target.files;
        setGallery(URL.createObjectURL(file));
    };

    const AddUpdateMiniGame = async () => {
        if (casualGame) { //Update
            formData.set("_id", casualGame.CasualGame._id);
            miniGameService.updateMiniGame(formData).then((response) => {
                setColor("2");
                setMessage("Mini Game updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })
        }
        else {
            if (formData.get("image") === null || formData.get("categoryId") === null
                || formData.get("gameLink") === null || formData.get("description") === null
                || formData.get("name") === null || formData.get("premium") === null || formData.get("rating") === null
                || formData.get("gallery") === null || formData.get("maxPlayers") === null || formData.get("isPortrait") === null) {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                miniGameService.addMiniGame(formData).then((response) => {
                    setColor("2");
                    setMessage("Mini game created successfully!");
                    setShowNotif(true);
                    dispatch(fetchCasualGames({ skip: 0, limit: 30 }))
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
    }
    const getCategories = async () => {
        miniGameService.getCategories().then(response => {
            setCategories(response.data.data)
        })
    }
    React.useEffect(() => {
        getCategories();
        if (casualGame) {
            setCategory(casualGame.category._id)
            setPremium(casualGame.CasualGame.premium)
            setIsPortrait(casualGame.CasualGame.isPortrait)
            setMaxPlayers(casualGame.CasualGame.maxPlayers)
        }
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update casualGame" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={MiniGamesIcon} style={{ height: "20px", width: "20px" }} />{casualGame ? 'Update' : 'Add'} Mini Game</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList style={{ backgroundColor: 'transparent' }}>
                        <Tab style={{ marginLeft: "2%" }}>Information</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Gallery & more</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Name </label>
                                                <Input placeholder="casualGame name" type="text" defaultValue={casualGame && casualGame.CasualGame.name} onChange={inputHandler} name="name" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Description </label>
                                                <Input placeholder="casualGame description" type="textarea" defaultValue={casualGame && casualGame.CasualGame.description} onChange={inputHandler} name="description" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Category</label><br />
                                                <select value={category} onChange={inputHandler} name="categoryId">
                                                    <option value="" disabled selected hidden>Select category</option>
                                                    {categories.map((category) => {
                                                        return (
                                                            <option key={category._id} value={category._id}>{category.title}</option>
                                                        );
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Game link</label><br />
                                                <Input placeholder="Game link" type="text" defaultValue={casualGame && casualGame.CasualGame.gameLink} onChange={inputHandler} name="gameLink" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Game code</label><br />
                                                <Input placeholder="Game code" type="text" defaultValue={casualGame && casualGame.CasualGame.code} onChange={inputHandler} name="gameLink" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Image link</label><br />
                                                <Input placeholder="Image link" type="text" defaultValue={casualGame && casualGame.CasualGame.image} onChange={inputHandler} name="image" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button
                                        className="next"
                                        onClick={() => handleButtonChange(1)}
                                    ><img src={ArrowIcon} /> Next</Button>
                                </Form>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Gallery </label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e) }} name="gallery" />
                                                </Button>
                                                <img src={(casualGame && gallery === undefined) ? casualGame.CasualGame.gallery : gallery} className="avatar" alt="gallery" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Rating</label>
                                                <Input placeholder="Rating" type="number" defaultValue={casualGame && casualGame.CasualGame.rating} onChange={inputHandler} name="rating" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Max players</label>
                                                <select value={maxPlayers} onChange={inputHandler} name="maxPlayers">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Premium ?</label>
                                                <select value={premium} onChange={inputHandler} name="premium">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Portrait ?</label>
                                                <select value={isPortrait} onChange={inputHandler} name="isPortrait">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(0)}
                                ><img src={BackArrowIcon} /> Back</Button>

                                <Button className='post' onClick={AddUpdateMiniGame}>{casualGame ? 'Update' : 'Post'}</Button>
                            </CreationForm>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateMiniGame);
import React from 'react';
import { AvatarIcon, BlueLockIcon, GreyClockIcon, GreyLockIcon, RemoveIcon, ThinkingIcon } from '../../../assets/img';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import styled from 'styled-components';
import authService from 'services/auth.service';
import Notification from 'utils/notification';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchAvatars } from 'redux/reducers/adminSlice';
import Loader from 'components/UI/Loader';
import PaginationBox from 'components/UI/PaginationBox';
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/adminSlice';
import CreateUpdateAvatar from './CreateUpdateAvatar';

const AvatarsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
`

const ContainerOfAvatar = styled.div`
    position: relative;
    min-height: 50px;
    min-width: 50px;
    margin: 10px 0 10px 0;
    flex-basis: calc(10% - 20px);
    img{
        width: 90px;
        height: 90px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
        cursor: pointer;
    }
`
const DeleteContainer = styled.div`
    position: absolute;
    left: 45px;
    transform: translateX(-50%);
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    max-width: 20px;
    cursor: pointer;
    img{
        max-height: 20px;
        max-width: 20px;
        min-height: 20px;
        min-width: 20px;
        border-radius: 50% !important;
    }

`
const PremiumAvatarContainer = styled.div`
    position: absolute;
    left: 45px;
    transform: translateX(-50%);
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    max-width: 20px;
    img{
        max-height: 20px;
        max-width: 20px;
        min-height: 20px;
        min-width: 20px;
        border-radius: 50% !important;
    }
`
const NoAvatars = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`

const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
`
const SortFilterDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: 250px;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
`

function Avatars() {
    const [deleteModal, setDeleteModal] = React.useState(false)
    const [avatar, setAvatar] = React.useState(undefined)
    const [avatarId, setAvatarId] = React.useState(undefined)
    const [avatarImage, setAvatarImage] = React.useState(undefined)
    const [avatarType, setAvatarType] = React.useState("All");
    const [avatarPrice, setAvatarPrice] = React.useState("All");
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [modal, setModal] = React.useState(false);
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const dispatch = useDispatch();

    const types = [
        { key: "All", value: "All" },
        { key: "profileAvatar", value: "Profile" },
        { key: "teamAvatar", value: "Team" }
    ]

    const price = [
        { key: "All", value: "All" },
        { key: "true", value: "Payable" },
        { key: "false", value: "Free" }
    ]

    const { loading, avatars, pageNumber } = useSelector(state => state.admins, shallowEqual)

    const getAllAvatars = (updatedType, updatedPrice) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        let filter = { pageNumber, skip }
        if (updatedType !== undefined && updatedType !== "All") filter.typeOfAvatar = updatedType
        if (updatedPrice !== undefined && updatedPrice !== "All") filter.payable = (updatedPrice === "true" ? true : false)
        if (updatedType !== avatarType || updatedPrice !== avatarPrice) {
            for (let i = pageNumber; i > 1; i--) {
                decrementPage()
            }
        }
        dispatch(fetchAvatars(filter))
    }

    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }

    const deleteAvatar = async (id) => {
        try {
            authService.deleteAvatar(id).then((response) => {
                setAvatarId(undefined);
                setAvatarImage(undefined);
                deleteToggle();
                dispatch(fetchAvatars({ skip }))
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    const toggle = (avatar) => {
        if (avatar !== undefined) {
            setAvatar(avatar)
        } else {
            setAvatar(undefined)
        }
        getAllAvatars(avatarType, avatarPrice);
        setModal(!modal)
    }

    React.useEffect(() => {
        getAllAvatars(avatarType, avatarPrice)
    }, [pageNumber])

    return (
        <>
            <div className="content">
                {showNotif && <Notification color={color} message={message} />}
                {modal && <CreateUpdateAvatar modal={modal} toggle={toggle} avatar={avatar} />}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={AvatarIcon} alt="avatars" style={{ marginRight: "8px" }} /> Avatars</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SortFilterDiv>
                                            <h5>Type: </h5>
                                            <select defaultValue="All" onChange={(e) => { setAvatarType(e.target.value); getAllAvatars(e.target.value, avatarPrice); }} name="avatarType">
                                                {types.map((type) => {
                                                    return (
                                                        <option key={type.key} value={type.key}>{type.value}</option>
                                                    );
                                                })}
                                            </select>
                                            <h5>Price: </h5>
                                            <select defaultValue="All" onChange={(e) => { setAvatarPrice(e.target.value); getAllAvatars(avatarType, e.target.value); }} name="price">
                                                {price.map((p) => {
                                                    return (
                                                        <option key={p.key} value={p.key}>{p.value}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                    !avatars.data ?
                                        <NoAvatars>
                                            <img src={ThinkingIcon} />
                                            <p>No avatars!</p>
                                        </NoAvatars> :
                                        <AvatarsContainer className='button-container'>
                                            {avatars.data.map((a, index) =>
                                                <>
                                                    <ContainerOfAvatar key={index}>
                                                        <DeleteContainer><img src={RemoveIcon} onClick={() => { deleteToggle(); setAvatarId(a._id); setAvatarImage(a.image) }} alt={index} /></DeleteContainer>
                                                        {a.state === true &&
                                                            <PremiumAvatarContainer><img src={BlueLockIcon} alt={index} key={index} /></PremiumAvatarContainer>
                                                        }
                                                        <img src={a.image} onClick={() => { toggle(a); }} alt={index} key={index} />
                                                    </ContainerOfAvatar>
                                                </>
                                            )}
                                        </AvatarsContainer>
                                }
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(avatars.totalNumberOfAvatars / limit)} totalNumber={avatars.totalNumberOfAvatars} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
                <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                    <ModalHeader toggle={deleteToggle}>Delete Avatar</ModalHeader>
                    <ModalBody>
                        <ContainerOfAvatar>
                            Are you sure you want to delete this avatar? <img src={avatarImage} />
                        </ContainerOfAvatar>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => deleteAvatar(avatarId)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
}

export default Avatars;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profile from '../../services/profile.service'

const initialState = {
    loading: false,
    quizThemes: [],
    quizQuestions: [],
    quizCategories: [],
    scores: [],
    alaanResponses: [],
    pageNumber: 1,
    error: ''
}

const QuizSlice = createSlice({
    name: 'quizzes',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;
        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(themes.fulfilled, (state, action) => {
            state.quizThemes = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(themes.pending, (state) => {
            state.loading = true
        })
        builder.addCase(themes.rejected, (state, action) => {
            state.quizThemes = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(questions.fulfilled, (state, action) => {
            state.quizQuestions = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(questions.pending, (state) => {
            state.loading = true
        })
        builder.addCase(questions.rejected, (state, action) => {
            state.quizQuestions = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(categories.fulfilled, (state, action) => {
            state.quizCategories = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(categories.pending, (state) => {
            state.loading = true
        })
        builder.addCase(categories.rejected, (state, action) => {
            state.quizCategories = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(leaderboard.fulfilled, (state, action) => {
            state.scores = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(leaderboard.pending, (state) => {
            state.loading = true
        })
        builder.addCase(leaderboard.rejected, (state, action) => {
            state.scores = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(alaanQuizResponses.fulfilled, (state, action) => {
            state.alaanResponses = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(alaanQuizResponses.pending, (state) => {
            state.loading = true
        })
        builder.addCase(alaanQuizResponses.rejected, (state, action) => {
            state.alaanResponses = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const themes = createAsyncThunk('quizzes/themes', async (payload) => {
    const allQuizThemes = await profile.getAllQuizTheme(payload)
    return (allQuizThemes.data);
})

export const questions = createAsyncThunk('quizzes/questions', async (payload) => {
    const allQuizQuestions = await profile.getAllQuizQuestions(payload.themeId, payload)
    return (allQuizQuestions.data);
})

export const categories = createAsyncThunk('quizzes/categories', async () => {
    const allQuizCategories = await profile.getAllCategories()
    return (allQuizCategories.data);
})

export const leaderboard = createAsyncThunk('quizzes/leaderboard', async (payload) => {
    const allScores = await profile.getScores(payload);
    return (allScores.data);
})

export const alaanQuizResponses = createAsyncThunk('quizzes/alaan', async (payload) => {
    const alaanResponses = await profile.alaanUsersWithTheirQuizResponses(payload);
    return (alaanResponses.data);
})


export const { incrementPageNumber, decrementPageNumber } = QuizSlice.actions
export default QuizSlice.reducer;
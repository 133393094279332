import axios from "axios";
import authHeader from "./auth.header";
let BASE_URL = "";
if ((window.location.href).includes("dash.t7dgaming.gg")) {
    BASE_URL = "https://profile.t7d.io"
} else {
    BASE_URL = "https://staging.profile.t7d.io"
    // BASE_URL = "http://localhost:5000"
}

// Profile
const getAllUsers = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/profile/findAll`,
        data: payload
    });

}
const addToMyAvatars = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/profile/addToMyAvatars`,
        data: payload,
        headers: authHeader()
    });
}
const GetAllProfilesWithWallet = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/profile/findAllProfilesWithWallet`,
        data: payload,
        headers: authHeader()
    });
}

const GetAllProfilesWithPagination = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/profile/findAllProfilesWithPagination`,
        data: payload,
        headers: authHeader()
    });
}

const banProfile = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/profile/banProfile`,
        data: payload,
        headers: authHeader()
    });
}

// Quiz category
const addCategory = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quizCategory/add`,
        data: payload,
        headers: authHeader()
    });
}

const updateCategory = async (id, payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quizCategory/update/${id}`,
        data: payload,
        headers: authHeader()
    });
}

const getCategory = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/quizCategory/${id}`,
    });
}

const deleteCategory = async (id) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quizCategory/delete/${id}`,
        headers: authHeader()
    });
}

const getAllCategories = async (payload) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/quizCategory/`,
        data: payload,
    });
}

// Quiz theme
const addQuizTheme = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/addQuizTheme`,
        data: payload,
        headers: authHeader()
    });
}

const getThemeById = async (id) => {
    return await axios({
        method: 'get',
        url: `${BASE_URL}/quiz/getThemeById/${id}`
    });
}

const updateQuizTheme = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/updateQuizTheme`,
        data: payload,
        headers: authHeader()
    });
}

const deleteQuizTheme = async (id) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/deleteQuizTheme/${id}`,
        headers: authHeader()
    });
}

const getAllQuizTheme = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/GetAllQuizThemes`,
        data: payload,
        headers: authHeader()
    });
}

// Quiz questions
const getAllQuizQuestions = async (themeId, payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/GetAllQuizQuestions/${themeId}`,
        data: payload,
        headers: authHeader()
    });
}

const addQuizQuestion = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/addQuizQuestion`,
        data: payload,
        headers: authHeader()
    });
}
const updateQuizQuestion = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/updateQuizQuestion`,
        data: payload,
        headers: authHeader()
    });
}

const deleteQuizQuestion = async (id) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/deleteQuizQuestion/${id}`,
        headers: authHeader()
    });
}

const getScores = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/GetScoreOfTopPlayersAdmin`,
        data: payload,
        headers: authHeader()
    });
}

// Score
const addPlayerTournamentScore = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/score/addTournamentScore`,
        data: payload,
        headers: authHeader()
    });
}

// Statistics
const getStatistics = async () => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/profile/getProfilesStatistics`,
        headers: authHeader()
    });
}

// Alaan
const alaanUsersWithTheirQuizResponses = async (payload) => {
    return await axios({
        method: 'post',
        url: `${BASE_URL}/quiz/displayUsersWithTheirResponses`,
        data: payload,
        headers: authHeader()
    });
}

export default {
    getAllUsers, addToMyAvatars, GetAllProfilesWithWallet, GetAllProfilesWithPagination, banProfile,
    addCategory, updateCategory, deleteCategory, getCategory, getAllCategories,
    addQuizTheme, updateQuizTheme, getThemeById, deleteQuizTheme, getAllQuizTheme,
    getAllQuizQuestions, addQuizQuestion, updateQuizQuestion, deleteQuizQuestion, getScores,
    addPlayerTournamentScore,
    getStatistics,
    alaanUsersWithTheirQuizResponses
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import miniGames from '../../services/mimiGames.service'

const initialState = {
    loading: false,
    casualGames: [],
    casualGamesCategories: [],
    pageNumber: 1,
    error: ''
}

const MiniGamesSlice = createSlice({
    name: 'casualGames',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;
        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCasualGames.fulfilled, (state, action) => {
            state.casualGames = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchCasualGames.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchCasualGames.rejected, (state, action) => {
            state.casualGames = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(fetchCasualGamesCategories.fulfilled, (state, action) => {
            state.casualGamesCategories = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchCasualGamesCategories.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchCasualGamesCategories.rejected, (state, action) => {
            state.casualGamesCategories = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchCasualGames = createAsyncThunk('miniGames/all', async (payload) => {
    const allMiniGames = await miniGames.getMiniGames(payload)
    return (allMiniGames.data);
})

export const fetchCasualGamesCategories = createAsyncThunk('miniGames/categories', async () => {
    const allCategories = await miniGames.getCategories()
    return (allCategories.data);
})


export const { incrementPageNumber, decrementPageNumber } = MiniGamesSlice.actions
export default MiniGamesSlice.reducer;
import React from "react";
import { Card, CardBody, Table, Row, Col, CardHeader, CardTitle } from "reactstrap";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PaginationBox from "components/UI/PaginationBox";
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/GdSlice';
import Loader from "components/UI/Loader";
import { fetchRedeemCodes } from "redux/reducers/GdSlice";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import NoData from "components/UI/NoData";
import { RedeemCodesIcon } from "assets/img";

const SortFilterDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: fit-content;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
`
const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
`

const RedeemCodes = () => {
    const [used, setUsed] = React.useState("All");
    const [dates, setDates] = React.useState(undefined);
    const [modal, setModal] = React.useState(false);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);

    const { afterToday } = DateRangePicker;
    const dispatch = useDispatch();

    const { loading, redeemCodesHistory, pageNumber, error } = useSelector((state) => state.gd, shallowEqual)

    const filters = [
        { key: "All", value: "All" },
        { key: true, value: "Used codes" },
        { key: false, value: "Not used codes" }
    ]

    React.useEffect(() => {
        filterData(used, dates);
    }, [pageNumber])

    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }
    const skipToFirstPage = () => {
        for (let i = pageNumber; i > 1; i--) {
            decrementPage()
        }
    }

    const filterData = (usedCodes, updatedDates) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        let sortBy = { pageNumber, skip, limit }
        if (usedCodes !== "All")
            sortBy.used = usedCodes
        if (updatedDates !== undefined && updatedDates !== null) {
            sortBy.startDate = updatedDates[0];
            sortBy.endDate = updatedDates[1];
        }
        if (usedCodes !== used || updatedDates !== dates) { // changing display
            skipToFirstPage();
        }
        dispatch(fetchRedeemCodes(sortBy))
    }
    return (
        <>
            <div className="content">
                <Row >
                    <Col md="12">
                        <Card style={{
                            height: '750px',
                            overflowY: 'auto',
                        }}>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={RedeemCodesIcon} alt="Store" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> Redeem Codes</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SortFilterDiv>
                                            <h4>Filter by:</h4>
                                            <select defaultValue="All" onChange={(e) => { setUsed(e.target.value); filterData(e.target.value, dates); }} name="usedCodes">
                                                {filters.map((filter) => {
                                                    return (
                                                        <option key={filter.key} value={filter.key}>{filter.value}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                        <DateRangePicker showOneCalendar
                                            size="sm"
                                            placeholder="Select Date Range"
                                            disabledDate={afterToday()}
                                            placement='bottomEnd'
                                            onChange={(date) => { setDates(date); filterData(used, date); }}
                                            onClean={() => setDates(undefined)}
                                        />
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive" >
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (redeemCodesHistory.data && redeemCodesHistory.data.length === 0) ?
                                            <NoData text={"No redeem codes history yet!"} />
                                            :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Code</th>
                                                        <th>SKU</th>
                                                        <th>Amount</th>
                                                        <th>Used</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {redeemCodesHistory.data && redeemCodesHistory.data.map((p, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td>{p.code}</td>
                                                                <td>{p.sku}</td>
                                                                <td>{p.amount}</td>
                                                                <td>{p.used ? "Yes" : "No"}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox
                            currentPage={pageNumber}
                            numberOfPages={Math.ceil((redeemCodesHistory.count) / limit)}
                            totalNumber={redeemCodesHistory.count}
                            decrement={decrementPage}
                            increment={incrementPage}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default RedeemCodes;

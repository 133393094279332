import React from 'react'
import ResultsGroupWinners from './ResultsGroupWinners'
import styled from 'styled-components';
import tournamentService from 'services/tournament.service';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups, getWinners } from '../../../redux/reducers/tournamentReducer';
import { ThinkingIcon } from 'assets/img';
import { Button } from 'reactstrap';

const Grid = styled.div`
    margin-top: 20px;
    width: 80%;
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:center;
    position: relative;
    gap:30px;
    .groupClass{
        width: 330px;
        height: 250px;
        flex: 1 0 21%;
        /* background-image: linear-gradient(168deg, #1b2573 7%, #ff465e 167%); */
    }
    .title{
        margin: auto;
        padding: 8px;
        height: 34px;
        width: 100%;
        background-image: linear-gradient(270deg, #2f1b4e 98%, #ff465e);
        color: #fe4958;
        text-indent: 10px;
    }
    .button{
        position: relative;
        top: 120px;
    }

`

const GenerateButton = styled.div`
    position: absolute;
    top: 55%;
    cursor: pointer;
    z-index: 20;
    
    button.generate{
        width: 100px;
        height: 27px;
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 13.5px;
        font-size: 11px;
        margin: 0;
        padding: 0;
    }
`;
const PopUp = styled.div`
    background-color: rgba(9,6,11,0.4);
    margin-top: 20px;
    width: 90%;
    height: 200px;
    border-radius: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const NoResults = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`


const ResultsSemiFinal = ({ id, toggle, format }) => {
    const [tournamentGame, setTournamentGame] = React.useState({})
    const [tournament, setTournament] = React.useState({})

    const dispatch = useDispatch()
    const winners = useSelector(state => state.tournament.semiFinalWinners)
    const players = useSelector(state => state.tournament.semiFinalGroupsPlayers)

    const data = { tournament_id: id, lvl: "semiFinal", condition: "overallScore" }

    React.useEffect(() => {
        tournamentService.getOneTournament(id).then((response) => {
            setTournament(response.data.data)
            setTournamentGame(response.data.data.gameId)
            dispatch(getWinners(data))
            dispatch(getGroups(data));
        })
    }, [])

    if (winners.length === 0) {
        return (
            <NoResults>
                <img src={ThinkingIcon} />
                <p>Semi-final results are not available at this moment,<br />
                    try generating them?</p>
                <GenerateButton>
                    <Button className='generate' onClick={toggle}>Generate</Button>
                </GenerateButton>
            </NoResults>
        )
    }

    return (
        <Grid>
            {winners.map((winner, index) => {
                return (
                    <div className='groupClass' key={index}>
                        <div className='title'>Group {index + 1} winners</div>
                        <ResultsGroupWinners tournamentGame={tournamentGame} tournament={tournament} GroupWinners={winner} players={players} groupNb={index}></ResultsGroupWinners>
                    </div>
                )
            })}
        </Grid>
    );
}

export default ResultsSemiFinal;
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'
import { BackArrowIcon, DeleteIcon, EditIcon, ProgressGIF, ThinkingIcon } from '../../../assets/img'
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getProductGiftCards } from 'redux/reducers/storeReducer';
import storeService from 'services/store.service';
import CreateUpdateGiftCard from './CreateUpdateGiftCard';

const Nav = styled.nav`
    border-radius: 14px 14px 0 0;
    background: rgba(117, 123, 168, 0.19);
    height: 85px;
`;

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .cancel{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
    .duplicate{
        cursor: pointer;
    }
`

const Progress = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  img{
    height: 300px;
  }
`
const NavMenu = styled.div`
    padding: 10px 16px 0 16px;
    button{
        cursor: pointer;
        padding: 16px 50px;
        background: transparent;
        color: white;
        border: none;
        :active{
            border-radius: 8px;
            background-color: #9ca2cf;
        }
    }
`
const NoGiftCards = styled.div`
    width: 90%;
    height: 600px;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
      width: 106px;
      height: 108px;
      position: absolute;
      top:25%;
    }
    p{
      color: '#9ca2cf';
      font-size: 16px;
      position: absolute;
      text-align: center;
    }
`

const Giftcards = () => {
    const [giftCard, setGiftCard] = React.useState()
    const [product, setProduct] = React.useState()
    const [giftCardId, setGiftCardId] = React.useState()
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    // skip & limit for giftcards
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(30);

    const navigate = useNavigate();
    const { id } = useParams();

    const giftCards = useSelector(state => state.store.productGiftCards)
    const dispatch = useDispatch();

    
    const NavigateToShop = () => {
        navigate("/Shop");
    }
    // Toggle for Modal
    const toggle = (gc) => {
        if (gc !== undefined) {
            setGiftCard(gc)
        } else {
            setGiftCard(undefined)
        }
        dispatch(getProductGiftCards({ skip, limit, productId: id }))
        setModal(!modal)
    }
    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }
    const deleteGiftCard = async (gcID) => {
        try {
            storeService.deleteGiftCard({ _id: gcID }).then((response) => {
                setGiftCardId("");
                deleteToggle();
                dispatch(getProductGiftCards({ skip, limit, productId: id }))
            })
        } catch (error) {
            console.log(error.message);
        }
    }
    
    const getProduct=()=>{
        storeService.getOneProduct(id).then((response) => {
            setProduct(response.data.data)
        })
    }
    
    React.useEffect(() => {
        dispatch(getProductGiftCards({ skip, limit, productId: id }))

    }, [])

    React.useEffect(() => {
        getProduct()
    }, [])
    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <Nav>
                            <NavMenu>
                            <h4><button onClick={() => NavigateToShop()} >
                                    <img src={BackArrowIcon} />
                                </button>
                                {product && product.title}</h4>
                            </NavMenu>
                        </Nav>
                        <br />
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <div className="table-full-width table-responsive" style={{
                                            maxHeight: '650px',
                                            overflowY: 'auto'
                                        }}>
                                            {giftCards.length === 0 ?
                                                <NoGiftCards>
                                                    <img src={ThinkingIcon} />
                                                    <p>No gift cards for this product!</p>
                                                </NoGiftCards> :
                                                <Table>
                                                    <thead className="text-primary" style={{ position: "sticky" }}>
                                                        <tr>
                                                            <th className="text-center">SKU</th>
                                                            <th className="text-center">Description</th>
                                                            <th className="text-center">Provider</th>
                                                            <th className="text-center">Regions</th>
                                                            <th className="text-center">GD price</th>
                                                            <th className="text-center">Rating</th>
                                                            <th className="text-center">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {giftCards.map((gc, index) => {
                                                            return (
                                                                <tr key={index} >
                                                                    <td className="text-center" style={{ cursor: "pointer" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                            {/* Image */}
                                                                            <div style={{ borderTopLeftRadius: "7px", borderBottomLeftRadius: "7px", height: "80px", width: "50px", backgroundImage: `url(${gc.icon})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                                                                            {/* End image */}
                                                                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                                                {gc.sku} <br />
                                                                                <div style={{ display: "flex" }}>
                                                                                    {gc.available ? "Available" : "Not available"}
                                                                                    {gc.hotDeal && "Hot Deal"}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">{gc.description}</td>
                                                                    <td>{gc.provider}</td>
                                                                    <td>{gc.regions.map(r => r)}</td>
                                                                    <td className="text-center">{gc.gdPrice.global}</td>
                                                                    <td className="text-center">{gc.rating}</td>
                                                                    <td className="text-center">
                                                                        <ActionStyle>
                                                                            <div className='cancel'><img src={EditIcon} alt="edit" onClick={() => { toggle(gc); }} /></div>
                                                                            <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setGiftCardId(gc._id) }} /></div>
                                                                        </ActionStyle>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            }
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                <ModalHeader toggle={deleteToggle}>Delete Gift card</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this gift card?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => deleteGiftCard(giftCardId)}>Yes</Button>{' '}
                    <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            {modal && <CreateUpdateGiftCard modal={modal} toggle={toggle} giftCard={giftCard} />}
        </div>);
}

export default Giftcards;
import React from 'react';
import styled from 'styled-components'
import { AddAvatarIcon, CategoryIcon } from '../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import { Dialog } from '@reach/dialog';
import "@reach/tabs/styles.css";
import Notification from '../../utils/notification';
import miniGameService from 'services/mimiGames.service';
import { useDispatch } from 'react-redux';
import { fetchCasualGamesCategories } from 'redux/reducers/miniGameSlice';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 100px;
        height: 80px;
        margin-left: 20px;
    }
`

const CreateUpdateCategory = ({ modal, toggle, category }) => {
    const [icon, setIcon] = React.useState(undefined);
    const [showNotif, setShowNotif] = React.useState(false);
    const [color, setColor] = React.useState();
    const [message, setMessage] = React.useState("");

    const formData = React.useMemo(() => new FormData(), []);

    const dispatch = useDispatch();

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === 'icon') {
            formData.set(name, e.target.files[0])
        }
        else formData.set(name, value)
    }
    const loadFile = (e) => {
        const [file] = e.target.files;
        setIcon(URL.createObjectURL(file));
    };

    const AddUpdateCategory = async () => {
        if (category) { //Update
            formData.set("_id", category._id)
            miniGameService.updateCategory(formData).then((response) => {
                setColor("2");
                setMessage("Mini game category updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })

        }
        else { // create new category
            if (formData.get("name") === null || formData.get("description") === null || formData.get("icon") === null) {
                miniGameService.addCategory(formData).then((response) => {
                    setColor("2");
                    setMessage("Mini game category created successfully!");
                    setShowNotif(true);
                    dispatch(fetchCasualGamesCategories());
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
            else {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
        }
    }

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update category" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={CategoryIcon} style={{ height: "20px", width: "20px" }} />  {category ? 'Update' : 'Add'} Mini Game category</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <CreationForm>
                    <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>

                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Title </label>
                                    <Input placeholder="Category name" type="text" defaultValue={category && category.title} onChange={inputHandler} name="title" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Description </label>
                                    <Input placeholder="Category description" type="textarea" defaultValue={category && category.description} onChange={inputHandler} name="description" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }}>
                            <Col className="px-md-1" md="12">
                                <FormGroup>
                                    <label>Icon </label>
                                    <br />
                                    <Button className='add'>
                                        <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e) }} name="icon" />
                                    </Button>
                                    <img src={(category && icon === undefined) ? category.icon : icon} className="avatar" alt="icon" />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button className='post' onClick={AddUpdateCategory}>{category ? 'Update' : 'Post'}</Button>
                    </Form>
                </CreationForm>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateCategory);
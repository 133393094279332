import React from 'react';
import axios from 'axios';
import { EditIcon, DeleteIcon, Premium, Kick, BanIcon, Switch, ViewIcon } from '../../assets/img';
import styled from 'styled-components';
import profileService from 'services/profile.service';
import { useDispatch } from 'react-redux';
import ViewTeam from './Teams/ViewTeam';

const ActionStyle = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    .kick{
        cursor: pointer;
    }
    .view{
        cursor: pointer;
    }
`

const ParticipantRow = ({ tournamentType, index, participant, allCountries, kick }) => {
    const [country, setCountry] = React.useState(null);
    const [modalViewTeam, setModalViewTeam] = React.useState(false)

    const toggleViewTeam = () => {
        setModalViewTeam(!modalViewTeam)
    }
    const fetchCountry = async (country) => {
        const countryObject = allCountries.find(({ countryName }) => countryName === country)
        countryObject ? setCountry(<img
            src={countryObject.logo}
            style={{ width: "25px", height: "25px" }} />) : setCountry("")
    }

    React.useEffect(() => {
        if (tournamentType !== "Teams")
            fetchCountry(participant.player.countryOfOrigin)
    }, [allCountries]);

    return (<>
        {modalViewTeam && <ViewTeam toggle={toggleViewTeam} modal={modalViewTeam} teamId={participant._id} />}
        {tournamentType === "Teams" ?
            <tr key={index}>
                <td><div style={{ display: "inline-flex", gap: "20px" }}><div className="photo"><img src={participant.teamInfo.teamLogo} /></div>{participant.teamInfo.teamName} </div></td>
                <td className="text-center">{participant.teamIsComplete ? "Yes" : "No"}</td>
                <td className="text-center"></td>
                <td className="text-center">
                    <ActionStyle>
                        <div className='view'><img src={ViewIcon} width="24" height="24" alt="View" onClick={() => toggleViewTeam()} /></div>
                        <div className='kick'><img src={Kick} alt="Kick" onClick={() => kick(participant)} /></div>
                    </ActionStyle>
                </td>
            </tr> :
            <tr key={index}>
                <td><div style={{ display: "inline-flex", gap: "20px" }}><div className="photo"><img src={participant.player.avatar} /></div>{participant.player.username} </div></td>
                <td className="text-center">{country}</td>
                <td className="text-center">{participant.player.isPremium.status ? <img src={Premium} style={{ width: "29px", height: "29px" }} /> : "Regular"}</td>
                <td className="text-center">{participant.participation.inGamePlayerId}</td>
                <td className="text-center"></td>
                <td className="text-center">
                    <ActionStyle>
                        <div className='kick'><img src={Kick} alt="Kick" onClick={() => kick(participant)} /></div>
                    </ActionStyle>
                    {/* <img style={{ paddingRight: "10px" }} src={Switch} /> */}
                    {/* <img style={{ paddingRight: "10px", cursor: "pointer" }} src={Kick} /> */}
                    {/* <img style={{ paddingRight: "10px" }} src={BanIcon} /> */}
                    {/* <img src={DeleteIcon} onClick={() => console.log("Hello")} /> */}
                </td>
            </tr>}
    </>
    );
}

export default ParticipantRow;
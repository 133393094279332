import React from 'react';
import moment from 'moment';
import { RedLike, Check, ClockIcon, Store, CheckoutLogo, FawriLogo, SobflousLogo, WalletIcon, ClickToPayIcon, KonnectIcon, GDIcon } from '../assets/img';
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchOrders } from 'redux/reducers/orderSlice';
import OrderDetails from 'components/Order/OrderDetails';
import orderService from 'services/order.service';
import DateRangePicker from 'rsuite/DateRangePicker';
import "rsuite/dist/rsuite.min.css";
import PaginationBox from 'components/UI/PaginationBox';
import Loader from 'components/UI/Loader';
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/orderSlice';


function Orders() {
  const [order, setOrder] = React.useState()
  const [total, setTotal] = React.useState()
  const [dayTotal, setDayTotal] = React.useState(0)
  const [dates, setDates] = React.useState(undefined);
  const [startDate, setStartDate] = React.useState(undefined);
  const [endDate, setEndDate] = React.useState(undefined);
  // Modal open state
  const [modal, setModal] = React.useState(false);
  // limit for orders
  const [limit, setLimit] = React.useState(20);

  const { afterToday } = DateRangePicker;
  const dispatch = useDispatch();

  const { loading, allOrders, pageNumber } = useSelector((state) => state.orders, shallowEqual)

  // Toggle for Modal
  const toggleOrderDetails = (o, total) => {
    setOrder(o)
    setTotal(total)
    setModal(!modal)
  }
  const decrementPage = () => {
    dispatch(decrementPageNumber());
  }

  const incrementPage = () => {
    dispatch(incrementPageNumber());
  }

  const getAllOrders = (pageNumber, limit, startDate, endDate) => {
    dispatch(fetchOrders({ pageNumber, limit, startDate, endDate }))
  }

  React.useEffect(() => {
    getAllOrders(pageNumber, limit, startDate, endDate);
  }, [pageNumber])

  const getProviderStatus = (products) => {
    let status = true
    products.map(p => {
      if (p.supplements.length === 0) status = false;
      else {
        p.supplements.map(s => {
          if (s.voucherKey && s.voucherKey.voucher === "N/A")
            status = false;
        })
      }
    })
    return status;
  }

  const getProviderLogo = (provider) => {
    if (provider === "checkout")
      return CheckoutLogo;
    else if (provider === "fawry")
      return FawriLogo;
    else if (provider === "sobflous")
      return SobflousLogo;
    else if (provider === "wallet")
      return WalletIcon;
    else if (provider === "clicktopay")
      return ClickToPayIcon;
    else if (provider === "gd")
      return GDIcon;
    else if (provider === "konnect")
      return KonnectIcon;
  }

  const getTotal = async (dates) => {
    let body = {};
    if (dates !== undefined && dates !== null) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      body = { startDate: dates[0], endDate: dates[1] };
      getAllOrders(pageNumber, limit, dates[0], dates[1])
    }
    else getAllOrders(pageNumber, limit);
    let total = 0;
    const totalResponse = await orderService.getTotal(body)
    if (totalResponse.data.data[0] !== undefined)
      total = parseFloat(totalResponse.data.data[0].total);
    setDayTotal(total);
  }

  React.useEffect(() => {
    getTotal(dates)
  }, [dates])

  return (
    <>
      {modal && <OrderDetails modal={modal} toggle={toggleOrderDetails} order={order} total={total} />}
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4"><img src={Store} alt="Store" style={{ marginRight: "8px" }} /> Orders</CardTitle>
                <CardTitle>
                  <div style={{ display: "flex", gap: "30px", flexFlow: "row-reverse" }}>
                    <h4>Total: {dayTotal} GD </h4>
                    <DateRangePicker showOneCalendar
                      size="sm"
                      placeholder="Select Date Range"
                      disabledDate={afterToday()}
                      placement='bottomEnd'
                      onChange={date => setDates(date)}
                      onClean={() => { setDates(undefined); setStartDate(undefined); setEndDate(undefined) }}
                    />
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody style={{ height: '620px', overflowY: 'scroll' }}>
                <div className="table-full-width table-responsive" >
                  <Table>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center"># Order</th>
                        <th className="text-center">Client</th>
                        <th className="text-center">Country</th>
                        <th className="text-center">Total amount</th>
                        <th className="text-center">Payment status</th>
                        <th className="text-center">Order status</th>
                        <th className="text-center">Purchase date</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(!loading && allOrders.data) ? allOrders.data.map((o, index) => {
                        return (
                          <tr key={index} >
                            <td className="text-center">{o.orderId}</td>
                            <td className="text-center">{o.profileId?.username}</td>
                            <td className="text-center"><img src={o.country.logo} style={{ width: "25px", height: "25px" }} /></td>
                            <td className="text-center">{o.finalTotalPrice?.afterDiscount} GD</td>
                            <td className="text-center">
                              {o.paymentStatus === true ? <p style={{ color: "#23e35d" }}><img src={Check} alt="check" /> Done</p> : <p style={{ color: "#fe4958" }}><img src={RedLike} alt="like" /> Error</p>}
                            </td>
                            <td className="text-center">
                              {o.orderStatus === true ? <p style={{ color: "#23e35d" }}>Success</p> : <p style={{ color: "#fe4958" }}>Error</p>}
                            </td>
                            <td className="text-center">{moment(o.createdAt).format('ll')} {moment(o.createdAt).format('LT')}</td>
                            <td className="text-center">
                              <i className="tim-icons icon-alert-circle-exc" style={{ cursor: "pointer" }} onClick={() =>
                                toggleOrderDetails(o, o.finalTotalPrice?.afterDiscount)} />
                            </td>
                          </tr>
                        );
                      }) : (<tr><td colSpan={9} style={{ paddingTop: "100px" }}><Loader /></td></tr>)}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <PaginationBox currentPage={pageNumber} numberOfPages={allOrders.numberOfPages} totalNumber={allOrders.totalNumber} decrement={decrementPage} increment={incrementPage} />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Orders;
import React from 'react';
import styled from 'styled-components'
import { AddAvatarIcon, AddIcon, ArrowIcon, BackArrowIcon, ProductsIcon, } from '../../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import { Dialog } from '@reach/dialog';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Notification from '../../../utils/notification';
import { useDispatch } from 'react-redux';
import storeService from 'services/store.service';
import { getProducts } from 'redux/reducers/storeReducer';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 100px;
        height: 80px;
        margin-left: 20px;
    }
`

const CreateUpdateProduct = ({ modal, toggle, product }) => {
    const [categories, setCategories] = React.useState([]);
    const [platforms, setPlatforms] = React.useState([]);
    const [logo, setLogo] = React.useState(undefined);
    const [coverImage, setCoverImage] = React.useState(undefined);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [showNotif, setShowNotif] = React.useState(false)
    const [visible, setVisible] = React.useState();
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")
    const [platform, setPlatform] = React.useState("");
    const [category, setCategory] = React.useState("");

    const formData = React.useMemo(() => new FormData(), []);
    const dispatch = useDispatch();

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === 'logo' || name === 'coverImage') {
            formData.set(name, e.target.files[0])
        } else if (name === "categoryId") {
            setCategory(value);
            formData.set(name, value);
        } else if (name === "platformId") {
            setPlatform(value);
            formData.set(name, value);
        } else if (name === "visible") {
            setVisible(value);
            formData.set(name, value);
        } else {
            formData.set(name, value)
        }
    }

    const handleButtonChange = (index) => {
        setTabIndex(index);
    };
    const handleTabsChange = (index) => {
        setTabIndex(index);
    };
    const loadFile = (e, name) => {
        const [file] = e.target.files;
        if (name === "logo")
            setLogo(URL.createObjectURL(file));
        else if (name === "coverImage")
            setCoverImage(URL.createObjectURL(file));
    };

    const AddUpdateProduct = async () => {
        if (product) { //Update
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            formData.set("_id", product._id);
            storeService.updateProduct(formData).then((response) => {
                dispatch(getProducts())
                setColor("2");
                setMessage("Product updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })

        }
        else { // create new product
            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            if (formData.get("title") === null || formData.get("company") === null
                || formData.get("description[en]") === null || formData.get("description[ar]") === null
                || formData.get("categoryId") === null || formData.get("platformId") === null
                || formData.get("logo") === null || formData.get("coverImage") === null) {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                storeService.addProduct(formData).then((response) => {
                    dispatch(getProducts())
                    setColor("2");
                    setMessage("Product created successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
    }

    const getPlatforms = async () => {
        storeService.getAllPlatforms().then(response => {
            setPlatforms(response.data.data)
        })
    }
    const getCategories = async () => {
        storeService.getAllCategories().then(response => {
            setCategories(response.data.data)
        })
    }
    React.useEffect(() => {
        getPlatforms();
        getCategories();
        if (product) {
            setPlatform(product.platformId._id)
            setCategory(product.categoryId._id)
            setVisible(product.visible)
            formData.set("description[ar]", product.description.ar)
            formData.set("description[en]", product.description.en)
        }
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update product" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={ProductsIcon} style={{ height: "20px", width: "20px" }} />  Add product</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList style={{ backgroundColor: 'transparent' }}>
                        <Tab style={{ marginLeft: "2%" }}>Information</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Description</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Gallery</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Product title </label>
                                                <Input placeholder="Product title" type="text" defaultValue={product && product.title} onChange={inputHandler} name="title" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Company </label>
                                                <Input placeholder="e.g. tencent" type="text" defaultValue={product && product.company} onChange={inputHandler} name="company" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Category</label><br />
                                                <select value={category} onChange={inputHandler} name="categoryId">
                                                    <option value="" disabled selected hidden>Select category</option>
                                                    {categories.map((category) => {
                                                        return (
                                                            <option key={category._id} value={category._id}>{category.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Platform</label><br />
                                                <select value={platform} onChange={inputHandler} name="platformId">
                                                    <option value="" disabled selected hidden>Select platform</option>
                                                    {platforms.map((platform) => {
                                                        return (
                                                            <option key={platform._id} value={platform._id}>{platform.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Visible ?</label>
                                                <select value={visible} onChange={inputHandler} name="visible">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button
                                        className="next"
                                        onClick={() => handleButtonChange(1)}
                                    ><img src={ArrowIcon} /> Next</Button>
                                </Form>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>English description</label>
                                                <Input placeholder="English description" type="textarea" defaultValue={product && product.description.en} onChange={inputHandler} name="description[en]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Arabic description</label>
                                                <Input placeholder="Arabic description" type="textarea" defaultValue={product && product.description.ar} onChange={inputHandler} name="description[ar]" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(0)}
                                ><img src={ArrowIcon} style={{ transform: "scaleX(-1)" }} /> Back</Button>
                                <Button
                                    className='next'
                                    onClick={() => handleButtonChange(2)}
                                ><img src={ArrowIcon} /> Next</Button>
                            </CreationForm>
                        </TabPanel>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Logo </label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'logo') }} name="logo" />
                                                </Button>
                                                <img src={(product && logo === undefined) ? product.logo : logo} className="avatar" alt="logo" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Cover</label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'coverImage') }} name="coverImage" />
                                                </Button>
                                                <img src={(product && coverImage === undefined) ? product.coverImage : coverImage} className="avatar" alt="cover" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(1)}
                                ><img src={BackArrowIcon} /> Back</Button>

                                <Button className='post' onClick={AddUpdateProduct}>{product ? 'Update' : 'Post'}</Button>
                            </CreationForm>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateProduct);
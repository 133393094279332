import { Dialog } from "@mui/material";
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Input, Row, Table } from "reactstrap";
import styled from "styled-components";
import tournamentService from "services/tournament.service";
import { Premium } from "assets/img";

const TeamModal = styled(Dialog)`
    
    .modalStyle{
        background-image: linear-gradient(to bottom, #3b437d, #1f265e);
    }
`
const ViewTeam = ({ toggle, modal, teamId }) => {
    const [teamInfo, setTeamInfo] = React.useState({})
    const [teamMembers, setTeamMembers] = React.useState([])

    const getTeamData = async () => {
        await tournamentService.getTeamMembers({ teamId }).then((response) => {
            setTeamInfo(response.data.teamInfo)
            setTeamMembers(response.data.teamMembers)
        })
    }
    React.useEffect(() => {
        getTeamData();
    }, [])

    return (
        <TeamModal
            fullWidth={true}
            maxWidth={"md"}
            open={modal}
            onClose={() => toggle()}>
            <div className="modalStyle">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4"> Team : {teamInfo?.teamName} </CardTitle></CardHeader>
                    <CardBody>
                        <Table borderless responsive style={{ tableLayout: "fixed" }}>
                            <thead>
                                <tr>
                                    <th className={"w-20"}>Team Member Name</th>
                                    <th className={"w-20"}>InGame Player ID</th>

                                </tr>
                            </thead>
                            <tbody>
                                {teamMembers.map((member, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{member?.username} {member?.isAdmin && <img src={Premium} style={{ width: "29px", height: "29px" }} />}</td>
                                            <td>{member?.inGamePlayerId}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </Table>
                    </CardBody>
                </Card>
            </div>
        </TeamModal>
    );
}

export default ViewTeam;
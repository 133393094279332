import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Overview from './Overview';
import Participants from './Participants';
import { useNavigate } from 'react-router-dom'
import { BackArrowIcon } from '../../assets/img'
import { Card, Col, Row, Table } from 'reactstrap';
import Groups from './Groups/Groups';
import tournamentService from 'services/tournament.service';
import Results from './Results/Results';
import { BracketsSE } from './Brackets/Brackets';
import GDWinners from './GD/GDWinners';
const Nav = styled.nav`
    border-radius: 14px 14px 0 0;
    background: rgba(117, 123, 168, 0.19);
    height: 85px;
`;

const PopUp = styled.div`
    background-color: rgba(9,6,11,0.4);
    width: 100%;
    height: 200px;
    border-radius: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`
const NavMenu = styled.div`
    padding: 10px 16px 0 16px;
    button{
        cursor: pointer;
        padding: 16px 50px;
        background: transparent;
        color: white;
        border: none;
        :active{
            border-radius: 8px;
            background-color: #9ca2cf;
        }
    }
`;

const TournamentDetails = () => {
    const navigate = useNavigate();
    const [active, setActive] = React.useState('overview')
    const [hide, setHide] = React.useState(true)
    const [tournamentFormat, setTournamentFormat] = React.useState(null);
    const [tournamentType, setTournamentType] = React.useState(null);
    const [tournamentEvent, setTournamentEvent] = React.useState(null);
    const [GDPrize, setGDPrize] = React.useState(false);
    const [nbRounds, setNbRounds] = React.useState(undefined);
    // check if e sports manager
    const [isEsportsManager, setIsESportsManager] = React.useState(false);

    const { id } = useParams();

    React.useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile) {
            setIsESportsManager(profile.roleId.title === 'e-sports manager');
        }
        tournamentService.getOneTournament(id).then((response) => {
            setTournamentFormat(response.data.data.tournamentFormat)
            setTournamentType(response.data.data.tournamentType)
            if (response.data.data.tournamentFormat === "Brackets")
                setNbRounds(response.data.data.brackets?.NumberOfRounds)
            setTournamentEvent(response.data.data.tournamentEvent)
            if (response.data.data.prizeClaim === "GD Counter part" || (response.data.data.gdPrize !== undefined && response.data.data.gdPrize !== null))
                setGDPrize(true);
            setHide(false)
        }).catch((error) => {
            if (error.response.status === '404') {
                setHide(true);
            }
        })
    }, [hide])

    if (hide) {
        return (
            <div className="content">
                <PopUp>Tournament not found</PopUp>
            </div>
        )
    }
    const NavigateToTournaments = () => {
        navigate("/Tournaments");
    }

    return (
        <div className="content">
            <Row>
                <Col md="12">
                    <Card style={{ height: "780px" }}>
                        <Nav>
                            <NavMenu>
                                <Table borderless responsive>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <button onClick={() => NavigateToTournaments()} >
                                                    <img src={BackArrowIcon} />
                                                </button></td>
                                            <td>
                                                <button onClick={() => setActive('overview')} >
                                                    Overview
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => setActive('participants')} >
                                                    {tournamentType === "Teams" ? "Teams" : "Participants"}
                                                </button>
                                            </td>
                                            {tournamentFormat !== "Brackets" &&
                                                <td>
                                                    <button onClick={() => setActive('results')} >
                                                        Results
                                                    </button>
                                                </td>
                                            }
                                            {tournamentFormat !== "Brackets" &&
                                                <td>
                                                    <button onClick={() => setActive('groups')} >
                                                        Groups
                                                    </button>
                                                </td>
                                            }
                                            {tournamentFormat === "Brackets" &&
                                                <td>
                                                    <button onClick={() => setActive('brackets')} >
                                                        Brackets
                                                    </button>
                                                </td>
                                            }
                                            {(GDPrize && isEsportsManager) &&
                                                <td>
                                                    <button onClick={() => setActive('grantGD')} >
                                                        Grant GDs
                                                    </button>
                                                </td>
                                            }

                                        </tr>

                                    </tbody>
                                </Table>
                            </NavMenu>
                        </Nav>
                        {active === 'overview' && <Overview tournamentId={id} />}
                        {active === 'participants' && <Participants id={id} tournamentType={tournamentType} />}
                        {active === 'results' && <Results id={id} format={tournamentFormat} event={tournamentEvent} />}
                        {active === 'groups' && <Groups id={id} format={tournamentFormat} />}
                        {active === 'brackets' && <BracketsSE id={id} event={tournamentEvent} />}
                        {active === 'grantGD' && <GDWinners id={id} format={tournamentFormat} nbRounds={nbRounds} />}
                    </Card>
                </Col>
            </Row>
        </div>);
}

export default TournamentDetails;
import React from 'react';
import { EditIcon, DeleteIcon, Store } from '../assets/img';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import CreateUpdateProduct from '../components/Store/Product/CreateUpdateProduct';
import { Stack, TablePagination } from '@mui/material';
import storeService from 'services/store.service';
import styled from 'styled-components';
import Notification from 'utils/notification';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from 'redux/reducers/storeReducer';

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .edit{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`
function Shop() {
  const navigate = useNavigate();
  const [product, setProduct] = React.useState()
  const [productId, setProductId] = React.useState()
  // Modal open state
  const [modal, setModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // Notification
  const [showNotif, setShowNotif] = React.useState(false)
  const [color, setColor] = React.useState()
  const [message, setMessage] = React.useState("")

  const dispatch = useDispatch();

  const allProducts = useSelector(state => state.store.allProducts)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Toggle for Modal
  const toggle = (p) => {
    if (p !== undefined) {
      setProduct(p)
    } else {
      setProduct(undefined)
    }
    dispatch(getProducts())
    setModal(!modal)
  }

  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  }
  const navigateDetails = (id) => {
    navigate(id);
  };

  React.useEffect(() => {
    dispatch(getProducts())
  }, [])

  const deleteProduct = async (id) => {
    try {
      storeService.deleteProduct({ _id: id }).then((response) => {
        setProductId("");
        deleteToggle();
        dispatch(getProducts())
      })
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <div className="content">
        {showNotif && <Notification color={color} message={message} />}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4"><img src={Store} alt="Store" style={{ marginRight: "8px" }} /> Shop</CardTitle>
              </CardHeader>
              <CardBody>
                {/* <Filters /> */}
                <div className="table-full-width table-responsive" style={{
                  maxHeight: '620px',
                  overflowY: 'auto'
                }}>
                  <Table>
                    {/* <thead className="text-primary" style={{display: "table", width: "calc(100% - 17px)"}}> */}
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">Product title</th>
                        <th className="text-center">Company</th>
                        <th className="text-center">Category</th>
                        <th className="text-center">Platform</th>
                        <th className="text-center">Action</th>
                        {/* <th className="text-center">Salary</th> */}
                      </tr>
                    </thead>
                    {/* <tbody style={{display: "block", maxHeight: '600px', overflowY: 'auto'}}> */}
                    <tbody>
                      {(rowsPerPage > 0
                        ? allProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : allProducts
                      )
                        .map((p, index) => {
                          return (
                            <tr key={index} >
                              <td className="text-center" style={{ cursor: "pointer" }} onClick={() => { navigateDetails(p._id) }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                  {/* Image */}
                                  <div style={{ borderTopLeftRadius: "7px", borderBottomLeftRadius: "7px", height: "80px", width: "50px", backgroundImage: `url(${p.coverImage})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}></div>
                                  {/* End image */}
                                  <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                    {p.title} <br />
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">{p.company}</td>
                              <td className="text-center">{p.categoryId.name}</td>
                              <td className="text-center"><img style={{ height: "70px", width: "50px" }} src={p.platformId.icon} /></td>
                              <td className="text-center">
                                <ActionStyle>
                                  <div className='edit'><img src={EditIcon} alt="edit" onClick={() => { toggle(p); }} /></div>
                                  <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setProductId(p._id) }} /></div>
                                </ActionStyle>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>

              <Stack>
                <TablePagination color='white'
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  count={allProducts.length}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  backIconButtonProps={{
                    "aria-label": "Previous Page",
                    style: { color: page === 0 ? "#b5b8c4" : "#7cb5ec" },
                    autoid: "pagination-button-next-collector",
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page",
                    style: { color: "#7cb5ec" },
                    autoid: "pagination-button-previous-collector",
                  }}
                />
              </Stack>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
          <ModalHeader toggle={deleteToggle}>Delete Product</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this product?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => deleteProduct(productId)}>Yes</Button>{' '}
            <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
        {modal && <CreateUpdateProduct modal={modal} toggle={toggle} product={product} />}
      </div>
    </>
  )
}

export default Shop;
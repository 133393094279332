import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import tournamentService from 'services/tournament.service';

const initialState = {
    loading: false,
    tournaments: [],
    participants: [],
    pageNumber: 1,
    error: ''
}

const TournamentSlice = createSlice({
    name: 'tournaments',
    initialState,
    reducers: {
        incrementPageNumber: (state) => {
            state.pageNumber += 1;

        },
        decrementPageNumber: (state) => {
            state.pageNumber -= 1;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTournaments.fulfilled, (state, action) => {
            state.tournaments = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchTournaments.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchTournaments.rejected, (state, action) => {
            state.tournaments = []
            state.loading = false
            state.error = action.error.message
        })

        builder.addCase(fetchParticipants.fulfilled, (state, action) => {
            state.participants = action.payload
            state.loading = false
            state.error = ''
        })
        builder.addCase(fetchParticipants.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchParticipants.rejected, (state, action) => {
            state.participants = []
            state.loading = false
            state.error = action.error.message
        })
    }
})

export const fetchTournaments = createAsyncThunk('tournaments/getTournaments', async (payload) => {
    const tournaments = await tournamentService.getAllTournaments(payload)
    return (tournaments.data);
})

export const fetchParticipants = createAsyncThunk('tournaments/getParticipants', async (payload) => {
    const participants = await tournamentService.getTournamentPlayersProfile(payload.id, payload)
    return (participants.data);
})


export const { incrementPageNumber, decrementPageNumber } = TournamentSlice.actions
export default TournamentSlice.reducer;
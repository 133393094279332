import React from "react";
import { Card, CardBody, Table, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ParticipantRow from "./ParticipantRow";
import tournamentService from "services/tournament.service";
import sharedDataService from "services/sharedData.service";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Notification from "utils/notification";
import NoData from "components/UI/NoData";
import { fetchParticipants } from "redux/reducers/tournamentSlice";
import { incrementPageNumber, decrementPageNumber } from "redux/reducers/tournamentSlice";
import Loader from "components/UI/Loader";
import PaginationBox from "components/UI/PaginationBox";


const Participants = ({ id, tournamentType }) => {
  const [allCountries, setAllCountries] = React.useState([]);
  const [player, setPlayer] = React.useState(undefined);
  const [kickModal, setKickModal] = React.useState(false);
  const [skip, setSkip] = React.useState(0);
  const [limit, setLimit] = React.useState(50);
  // Notification
  const [showNotif, setShowNotif] = React.useState(false)
  const [color, setColor] = React.useState()
  const [message, setMessage] = React.useState("")

  const dispatch = useDispatch()
  const { loading, participants, pageNumber } = useSelector((state) => state.tournamentSlice, shallowEqual)

  const getCountries = async () => {
    sharedDataService.getCountries().then((response) => {
      setAllCountries(response.data.data);
    });
  };
  const kickToggle = (playerData) => {
    if (playerData !== undefined) {
      setPlayer(playerData);
    }
    else setPlayer(undefined);
    setKickModal(!kickModal)
  }
  const kickPlayer = async (participationId) => {
    try {
      tournamentService.kickPlayer({ tournamentId: id, participationId }).then((response) => {
        dispatch(fetchParticipants({ id, skip, limit }))
        kickToggle();
        setColor("2");
        setMessage("Player kicked successfully from tournament!");
        setShowNotif(true);
        setTimeout(() => {
          setShowNotif(false)
        }, 2000);
      })
    } catch (error) {
      console.log(error.message);
    }
  }
  const decrementPage = () => {
    dispatch(decrementPageNumber());
  }

  const incrementPage = () => {
    dispatch(incrementPageNumber());
  }

  const getAllParticipants = () => {
    const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
    setSkip(skip);
    dispatch(fetchParticipants({ id, skip, limit }))
  }

  React.useEffect(() => {
    getAllParticipants()
    getCountries();
  }, []);
  React.useEffect(() => {
    getAllParticipants();
  }, [pageNumber])

  return (
    <>
      <div className="content">
        {showNotif && <Notification color={color} message={message} />}
        <Modal isOpen={kickModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={kickToggle} >
          <ModalHeader toggle={kickToggle}>Kick {tournamentType === "Teams" ? "team" : "player"}</ModalHeader>
          <ModalBody>
            Are you sure you want to kick {(player && tournamentType === "Teams") ? player?.teamInfo?.teamName : player?.player?.username} from this tournament?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => kickPlayer(tournamentType === "Teams" ? player._id : player.participation._id)}>Yes</Button>{' '}
            <Button color="secondary" onClick={() => kickToggle()}>Cancel</Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col md="12">
            <Card style={{
              height: '600px',
              overflowY: 'auto'
            }}>
              <CardBody>
                <div className="table-full-width table-responsive" style={{
                  maxHeight: '600px',
                  overflowY: 'auto'
                }}>
                  {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                    (participants.data && participants.data.length === 0) ?
                      <NoData text={"Still no registrations yet! Are you really a tournament organizer?"} /> :
                      <Table>
                        <thead className="text-primary" style={{ position: "sticky" }}>
                          <tr>
                            <th className="text-center">All {tournamentType === "Teams" ? "teams" : "players"}</th>
                            {tournamentType !== "Teams" && <th className="text-center">Country</th>}
                            {tournamentType === "Teams" && <th className="text-center">Completed team</th>}
                            {tournamentType !== "Teams" && <th className="text-center">Subscription</th>}
                            {tournamentType !== "Teams" && <th className="text-center">Game ID</th>}
                            {tournamentType !== "Teams" && <th className="text-center">Total Wins</th>}
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {participants.data && participants.data.map((p, index) => (
                            <ParticipantRow
                              tournamentType={tournamentType}
                              key={index}
                              index={index}
                              participant={p}
                              allCountries={allCountries}
                              kick={kickToggle}
                            />
                          ))}
                        </tbody>
                      </Table>
                  }
                </div>
              </CardBody>
            </Card>
            <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(participants.totalNbOfParticipants / limit)} totalNumber={participants.totalNbOfParticipants} decrement={decrementPage} increment={incrementPage} />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Participants;

import React from "react";
import { Card, CardBody, Table, Row, Col, CardHeader, CardTitle } from "reactstrap";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PaginationBox from "components/UI/PaginationBox";
import { decrementPageNumber, incrementPageNumber } from 'redux/reducers/quizSlice';
import Loader from "components/UI/Loader";
import NoData from "components/UI/NoData";
import { alaanQuizResponses } from "redux/reducers/quizSlice";
import { AlaanIcon } from "assets/img";
import { DateRangePicker } from "rsuite";
import orderService from "services/order.service";

const NumberDiv = styled.div`
    width: 35px;
    font-size: 20px;
    text-align: center;
    border-radius: 13px;
    background-image: linear-gradient(to bottom, rgba(27, 37, 115,.13), rgba(255, 70, 94,.13));
`

const Alaan = () => {
    const [dates, setDates] = React.useState(undefined);
    const [startDate, setStartDate] = React.useState(undefined);
    const [endDate, setEndDate] = React.useState(undefined);
    const [quizTotal, setQuizTotal] = React.useState(0);
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);

    const { afterToday } = DateRangePicker;
    const dispatch = useDispatch();

    const { loading, alaanResponses, pageNumber } = useSelector((state) => state.quiz, shallowEqual)

    const getAllResponses = (limit, startDate, endDate) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        getTotal(startDate, endDate)
        dispatch(alaanQuizResponses({ skip, limit, startDate, endDate }))
    }
    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }

    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }
    const getTotal = async (startDate, endDate) => {
        let total = 0;
        const totalResponse = await orderService.getAlaanTotalPayment({ startDate, endDate })
        if (totalResponse.data.total.length > 0)
            total = parseFloat(totalResponse.data.total[0].totalPrice);
        setQuizTotal(total);
    }
    React.useEffect(() => {
        getAllResponses(limit, startDate, endDate);
    }, [pageNumber, dates])

    React.useEffect(() => {
        getTotal(startDate, endDate)
    }, [])

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card style={{ height: '730px', overflowY: 'auto' }}>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={AlaanIcon} alt="Store" style={{ marginRight: "8px", width: "30px", height: "30px" }} /> Alaan Quiz Responses</CardTitle>
                                <CardTitle>
                                    <div style={{ display: "flex", gap: "30px", flexFlow: "row-reverse" }}>
                                        <h4>Total Payments: {quizTotal} AED </h4>
                                        <DateRangePicker showOneCalendar
                                            size="sm"
                                            placeholder="Select Date Range"
                                            disabledDate={afterToday()}
                                            placement='bottomEnd'
                                            onChange={date => { setDates(date); setStartDate(date ? date[0] : undefined); setEndDate(date ? date[1] : undefined) }}
                                            onClean={() => { setDates(undefined); setStartDate(undefined); setEndDate(undefined) }}
                                        />
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* <Filters /> */}
                                <div className="table-full-width table-responsive">
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (alaanResponses.data && alaanResponses.data.length === 0) ? <NoData text={"No responses to show yet!"} /> :
                                            <Table>
                                                {/* <thead className="text-primary" style={{display: "table", width: "calc(100% - 17px)"}}> */}
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Email</th>
                                                        <th>Total Answered Quizzes</th>
                                                        <th>Total Correct Answers </th>
                                                        <th>Total Paid Answers</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {alaanResponses.data && alaanResponses.data.map((s, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td>
                                                                    <div style={{ display: "inline-flex", gap: "30px" }}>
                                                                        <NumberDiv style={{ color: '#fe4958' }}>{skip + index + 1}</NumberDiv>
                                                                        {s.email}
                                                                    </div>
                                                                </td>
                                                                <td>{s.totalAnswers}</td>
                                                                <td>{s.correctAnswers}</td>
                                                                <td>{s.paidAnswers}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(alaanResponses.totalCount / 20)} totalNumber={alaanResponses.totalCount} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default Alaan;

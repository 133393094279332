import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// reactstrap components
import {
  Button, ButtonGroup, Card, CardHeader, CardBody, CardTitle, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
  Label, FormGroup, Input, Table, Row, Col, UncontrolledTooltip
} from "reactstrap";

// core components
import { chartExample1, chartExample2, chartExample3, chartExample4 } from "variables/charts.js";
// import { purchaseData } from "../Charts/purchaseData";
import orderService from "services/order.service";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import profileService from "services/profile.service";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function Dashboard(props) {
  // const [bigChartData, setbigChartData] = React.useState("data1");
  const [nbSells, setNbSells] = React.useState("");
  const [totalPurchases, setTotalPurchases] = React.useState("");
  const [totalGDGiftCardSold, setTotalGDGiftCardSold] = React.useState("");
  const [purchasesByGateway, setPurchasesByGateway] = React.useState("");
  const [successPercentage, setSuccessPercentage] = React.useState();
  const [labels, setLabels] = React.useState([]);
  const [dataset, setDataSet] = React.useState([]);
  const [profileStatistics, setProfileStatistics] = React.useState({});

  const { afterToday } = DateRangePicker;

  const getTotalNumberOfSells = async (dates) => {
    let body = {};
    if (dates !== undefined && dates !== null) {
      let start = dates[0];
      let end = dates[1];
      body = { startDate: start, endDate: end };
    }
    await orderService.getNumberOfSells(body).then(response => {
      setNbSells(response.data.data.numberOfSells)
      if (response.data.data.totalSells !== 0)
        setSuccessPercentage(((response.data.data.numberOfSells / response.data.data.totalSells) * 100).toFixed(2));
      else setSuccessPercentage(0);
    }).catch(err => {
      console.log(err);
    })
  }
  const getTotalAmountOfPurchases = async (dates) => {
    let body = {};
    if (dates !== undefined && dates !== null) {
      let start = dates[0];
      let end = dates[1];
      body = { startDate: start, endDate: end };
    }
    await orderService.getTotalPurchases(body).then(response => {
      setTotalPurchases(response.data.data.total);
    }).catch(err => {
      console.log(err);
    })
  }
  const getTotalGDGiftCardSold = async (dates) => {
    let body = {};
    if (dates !== undefined && dates !== null) {
      let start = dates[0];
      let end = dates[1];
      body = { startDate: start, endDate: end };
    }
    await orderService.getTotalGDGiftCardsSold(body).then(response => {
      setTotalGDGiftCardSold(response.data.data.total);
    }).catch(err => {
      console.log(err);
    })
  }
  const getPurchasesByPaymentGateway = async (dates) => {
    let body = {};
    if (dates !== undefined && dates !== null) {
      let start = dates[0];
      let end = dates[1];
      body = { startDate: start, endDate: end };
    }
    await orderService.getNumberAndTotalOfPurchasesByGateway(body).then(response => {
      setPurchasesByGateway(response.data.data);
    }).catch(err => {
      console.log(err);
    })
  }

  const getSuccessPurchasesOfTheWeek = async () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
    const labels = [];
    let currentDate = sevenDaysAgo;
    while (currentDate <= today) {
      labels.push(currentDate.toLocaleDateString('en-GB'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const weekSells = await orderService.getSuccessPurchasesOfTheWeek().then(res => { return res.data });
    let allWeek = [];
    labels.map(label => {
      const s = weekSells.find(s => s.date === label);
      allWeek.push(s === undefined ? 0 : s.count);
    })
    setLabels(labels);
    setDataSet(allWeek);
  }
  const getProfileStats = async () => {
    await profileService.getStatistics().then(response => {
      setProfileStatistics(response.data.data);
    }).catch(err => {
      console.log(err);
    })
  }

  const updateDash = (date) => {
    getTotalNumberOfSells(date);
    getTotalAmountOfPurchases(date);
    getPurchasesByPaymentGateway(date);
    getTotalGDGiftCardSold(date)
  }
  React.useEffect(() => {
    getTotalNumberOfSells()
    getTotalAmountOfPurchases()
    getPurchasesByPaymentGateway()
    getSuccessPurchasesOfTheWeek()
    getTotalGDGiftCardSold()
    getProfileStats()
  }, [])

  const data = {
    labels,
    datasets: [
      {
        label: "Successful sells",
        data: dataset,
        borderColor: "#d048b6",
        borderWidth: 1
      }
    ]
  }
  const options = {}

  return (
    <>
      <div className="content">
        <div style={{ display: "flex", gap: "30px", flexFlow: "row-reverse" }}>
          <DateRangePicker showOneCalendar
            size="sm"
            placeholder="Select Date Range"
            disabledDate={afterToday()}
            placement='bottomEnd'
            onChange={date => { updateDash(date) }}
          // onClean={() => getTotal()}
          />
        </div>
        <br />

        <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total number of sells</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-info" />
                  {nbSells && nbSells} ({successPercentage}%)
                </CardTitle>
              </CardHeader>
              <CardBody>
                <p>Last week's successful sells</p>
                <div className="chart-area">
                  <Bar
                    data={data}
                    options={options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total amount of purchases</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-money-coins text-primary" />{" "}
                  {totalPurchases && totalPurchases.toFixed(3)} $
                </CardTitle>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total GD used to purchase GiftCards</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-money-coins text-primary" />{" "}
                  {totalGDGiftCardSold && totalGDGiftCardSold} GD
                </CardTitle>
              </CardHeader>
            </Card>

            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Purchases by payment gateway</h5>
                <CardTitle tag="h3">
                  {/* <i className="tim-icons icon-chart-bar-32 text-success" /> */}
                </CardTitle>
              </CardHeader>

              {purchasesByGateway.length !== 0 &&
                <CardBody>
                  <table style={{ width: "100%", color: "white" }}>
                    <tbody>
                      {purchasesByGateway.map((p, index) => {
                        return (
                          <tr key={index}>
                            <td><h4>{p._id}</h4></td>
                            <td><h5><i className="tim-icons icon-delivery-fast text-info" />{" "}{p.numberOfSells}</h5></td>
                            <td><h5><i className="tim-icons icon-money-coins text-primary" />{" "}{p.total.toFixed(3)} $</h5></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </CardBody>
              }
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h3 className="card-category">Active Users</h3>
                <CardTitle tag="h5">
                  <i className="tim-icons icon-satisfied text-success" />{" "}
                  {profileStatistics && profileStatistics.dailyActive} Today <br />
                  <i className="tim-icons icon-satisfied text-success" />{" "}
                  {profileStatistics && profileStatistics.weeklyActive} This week
                </CardTitle>
              </CardHeader>
            </Card>
            <Card className="card-chart">
              <CardHeader>
                <h3 className="card-category">New Accounts</h3>
                <CardTitle tag="h5">
                  <i className="tim-icons icon-single-02 text-success" />{" "}
                  {profileStatistics && profileStatistics.dailyNewAccounts} Created not yet activated accounts today<br />
                  <i className="tim-icons icon-controller text-warning" />{" "}
                  {profileStatistics && profileStatistics.dailyNewUsers} Created & activated accounts today<br />
                  <i className="tim-icons icon-controller text-warning" />{" "}
                  {profileStatistics && profileStatistics.weeklyNewUsers} Created & activated accounts this week
                </CardTitle>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;

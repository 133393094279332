import React from 'react';
import styled from 'styled-components'
import { AddAvatarIcon, AddIcon, ArrowIcon, AvatarIcon, BackArrowIcon, ProductsIcon, } from '../../../assets/img';
import { Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import { Dialog } from '@reach/dialog';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Notification from '../../../utils/notification';
import authService from 'services/auth.service';
import { fetchAvatars } from 'redux/reducers/adminSlice';
import { useDispatch } from 'react-redux';

const CreationForm = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    input{
        background-color: #322e60;
        border-radius: 17px;
    }
    input[type=datetime-local]::-webkit-calendar-picker-indicator {
        cursor:pointer;
        filter: invert(1);
    }
    input[type=datetime-local]::-webkit-datetime-edit{
        color: #9ca2cf;
    }
    select{
        width: 100%;
        height: 40px;
        background-color: #322e60;
        border-radius: 17px;
        border-color: transparent;
        color: rgba(255,255,255,.5); 
        text-indent: 15px;
        font-size: smaller;
        :focus{
            border-color: #fe4958;
        }
    }
    Form{
        flex-direction: "column"; display: 'flex'; align-items: "center"; justify-content: "center"; width: "100%";
        button{
            margin-top: 10%;
            margin-bottom: -15%;
        }
    }
    button.next{
        background-color: #fe4958 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #fe4958 !important;
            background-image: none !important;
        }
    }
    button.back{
        color: #9ca2cf;
        background-color: #656fb5 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #656fb5 !important;
            background-image: none !important;
        }
    }
    button.post{
        width:50%;
        background-color: #12aa01 !important;
        background-image: none !important;
        border-radius: 17px;
        font-size: smaller;
        :hover{
            background-color: #12aa01 !important;
            background-image: none !important;
        }
    }
    button.add{
        border: none;
        color: white;
        padding: 20px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-image: url(${AddAvatarIcon});
        background-size: cover;
        :hover{
            background-image: url(${AddAvatarIcon}) !important;
        }
    }
    img.avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        margin-left: 20px;
    }
`

const CreateUpdateAvatar = ({ modal, toggle, avatar }) => {
    const [image, setImage] = React.useState(undefined);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [showNotif, setShowNotif] = React.useState(false)
    const [showInfo, setShowInfo] = React.useState(false)
    const [payable, setPayable] = React.useState();
    const [state, setState] = React.useState();
    const [exclusive, setExclusive] = React.useState();
    const [avatarType, setAvatarType] = React.useState();
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const formData = React.useMemo(() => new FormData(), []);
    const dispatch = useDispatch();

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === 'image') {
            formData.set(name, e.target.files[0])
        } else if (name === "payable") {
            if (value === "true") setShowInfo(true);
            else setShowInfo(false);
            setPayable(value);
            formData.set("info[payable]", value);
        } else if (name === "avatarType") {
            setAvatarType(value);
            formData.set(name, value);
        } else if (name === "state") {
            setState(value);
            formData.set(name, value);
        } else if (name === "exclusive") {
            setExclusive(value);
            formData.set(name, value);
        } else {
            formData.set(name, value)
        }
    }

    const handleButtonChange = (index) => {
        setTabIndex(index);
    };
    const handleTabsChange = (index) => {
        setTabIndex(index);
    };
    const loadFile = (e, name) => {
        const [file] = e.target.files;
        setImage(URL.createObjectURL(file));
    };

    const AddUpdateAvatar = async () => {
        if (avatar) { //Update
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            authService.updateAvatar(avatar._id, formData).then((response) => {
                fetchAvatars({ skip: 0 })
                setColor("2");
                setMessage("Avatar updated successfully!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                    toggle();
                }, 2000);
            }).catch((error) => {
                console.log(error.message);
            })

        }
        else { // create new avatar
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            if (formData.get("image") === null || formData.get("title") === null || formData.get("state") === null
                || formData.get("exclusive") === null || formData.get("avatarType") === null || formData.get("info[payable]") === null
                || (formData.get("info[payable]") === "true" && formData.get("info[gdprice]") === null && formData.get("info[discount]") === null)
            ) {
                setColor("3");
                setMessage("Please complete the missing fields!");
                setShowNotif(true);
                setTimeout(() => {
                    setShowNotif(false)
                }, 7000);
            }
            else {
                authService.addAvatar(formData).then((response) => {
                    dispatch(fetchAvatars({ skip: 0 }))
                    setColor("2");
                    setMessage("Avatar created successfully!");
                    setShowNotif(true);
                    setTimeout(() => {
                        setShowNotif(false)
                        toggle();
                    }, 2000);
                }).catch((error) => {
                    console.log(error.message);
                })
            }
        }
    }

    React.useEffect(() => {
        if (avatar) {
            setPayable(avatar.info.payable)
            if (avatar.info.payable === true) setShowInfo(true)
            setAvatarType(avatar.avatarType)
            setState(avatar.state)
            setExclusive(avatar.exclusive)
            formData.set("info[payable]", avatar.info.payable)
            formData.set("info[gdprice]", avatar.info.gdprice)
            formData.set("info[discount]", avatar.info.discount)
        }
    }, [])

    return (
        <>
            {showNotif && <Notification color={color} message={message} />}
            <Dialog aria-label="Add/Update avatar" className='creationModal' isOpen={modal} onDismiss={toggle}>
                <div className='title'><img src={AvatarIcon} style={{ height: "20px", width: "20px" }} />  Add avatar</div>
                <button className="close-button" onClick={toggle}>
                    <span aria-hidden>×</span>
                </button>
                <br />
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList style={{ backgroundColor: 'transparent' }}>
                        <Tab style={{ marginLeft: "2%" }}>Information</Tab>
                        <Tab style={{ marginLeft: "2%" }}>Gallery</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Title </label>
                                                <Input placeholder="Avatar title" type="text" defaultValue={avatar && avatar?.title} onChange={inputHandler} name="title" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Type</label>
                                                <select value={avatarType} onChange={inputHandler} name="avatarType">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="profileAvatar">Profile</option>
                                                    <option value="teamAvatar">Team</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Payable ?</label>
                                                <select value={payable} onChange={inputHandler} name="payable">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {showInfo &&
                                        <>
                                            <Row style={{ width: "100%" }}>
                                                <Col className="px-md-1" md="12">
                                                    <FormGroup>
                                                        <label>GD price </label>
                                                        <Input placeholder="GD price" type="text" defaultValue={avatar && avatar?.info.gdprice} onChange={inputHandler} name="info[gdprice]" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row style={{ width: "100%" }}>
                                                <Col className="px-md-1" md="12">
                                                    <FormGroup>
                                                        <label>Discount </label>
                                                        <Input placeholder="Discount" type="text" defaultValue={avatar && avatar?.info.discount} onChange={inputHandler} name="info[discount]" />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>}
                                    <Button
                                        className="next"
                                        onClick={() => handleButtonChange(1)}
                                    ><img src={ArrowIcon} /> Next</Button>
                                </Form>
                            </CreationForm>
                        </TabPanel>

                        <TabPanel>
                            <CreationForm>
                                <Form style={{ flexDirection: "column", display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", padding: "50px 0px 50px 0px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Image </label>
                                                <br />
                                                <Button className='add'>
                                                    <Input type="file" id="ImageHandler" accept="image/png, image/jpeg" onChange={(e) => { inputHandler(e); loadFile(e, 'image') }} name="image" />
                                                </Button>
                                                <img src={(avatar && image === undefined) ? avatar.image : image} className="avatar" alt="avatar" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>State ?</label>
                                                <select value={state} onChange={inputHandler} name="state">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%" }}>
                                        <Col className="px-md-1" md="12">
                                            <FormGroup>
                                                <label>Exclusive ?</label>
                                                <select value={exclusive} onChange={inputHandler} name="exclusive">
                                                    <option value="" disabled selected hidden>Select option</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <Button
                                    className='back'
                                    onClick={() => handleButtonChange(0)}
                                ><img src={BackArrowIcon} /> Back</Button>

                                <Button className='post' onClick={AddUpdateAvatar}>{avatar ? 'Update' : 'Post'}</Button>
                            </CreationForm>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Dialog >
        </>
    )
}

export default React.memo(CreateUpdateAvatar);
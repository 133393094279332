import { Dialog } from "@mui/material";
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Input, Row, Table } from "reactstrap";
import styled from "styled-components";
import GroupPlayerRow from "./GroupPlayerRow";
import { getGroups } from '../../../redux/reducers/tournamentReducer';
import { useDispatch } from 'react-redux'
const PlayersModal = styled(Dialog)`
    
    .modalStyle{
        background-image: linear-gradient(to bottom, #3b437d, #1f265e);
    }
`
const ShowMorePlayers = ({ toggle, modal, players, groupNumber, tournamentId, tournamentGame, lvl }) => {
    const dispatch = useDispatch()

    const [showedTr, setShowedTr] = React.useState({ kills: true, goals: true, placement: true, deaths: true, assist: true })
    // let showedTr = { kills: true, goals: true, placement: true, deaths: true, assists: true };

    React.useEffect(() => {
        console.log(tournamentGame.title);
        if (tournamentGame.title.includes('Free Fire') || tournamentGame.title.includes('Pubg') || tournamentGame.title.includes('Call Of Duty')) {
            setShowedTr(values => ({ ...values, assist: false, goals: false }))
        } else if (tournamentGame.title.includes('FIFA') || tournamentGame.title.includes('Rocket League')) {
            setShowedTr(values => ({ ...values, placement: false, kills: false, deaths: false }))
        }
        else {
            setShowedTr(values => ({ ...values, placement: false, goals: false }))
        }

    }, [])

    const updateState = () => {
        dispatch(getGroups({
            tournament_id: tournamentId,
            lvl: lvl
        }))
    }
    return (
        <PlayersModal
            fullWidth={true}
            maxWidth={"md"}
            open={modal}
            onClose={() => { toggle(); updateState() }}>
            <div className="modalStyle">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4"> Group {groupNumber} players</CardTitle></CardHeader>
                    <CardBody>
                        <Table borderless responsive style={{ tableLayout: "fixed" }}>
                            <thead>
                                <tr>
                                    <th className={"w-20"}>Player Name</th>
                                    <th className={"w-20"}>InGame Player ID</th>
                                    {showedTr.goals && <th className="text-center">Goals</th>}
                                    {showedTr.kills && <th className="text-center">Kills</th>}
                                    {showedTr.placement && <th className="text-center">Placement</th>}
                                    {showedTr.deaths && <th className="text-center">Deaths</th>}
                                    {showedTr.assist && <th className="text-center">Assists</th>}
                                    <th className="text-center">Overall score</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {players.sort((a, b) => { return b.score.overallScore - a.score.overallScore }).map((player, index) => <GroupPlayerRow index={index} player={player} tournamentId={tournamentId} showedTr={showedTr} lvl={lvl} />)}
                            </tbody>

                        </Table>
                    </CardBody>
                </Card>
            </div>
        </PlayersModal>
    );
}

export default ShowMorePlayers;

import React from 'react';
import { EditIcon, DeleteIcon, RamadanIcon, QuizThemeIcon, CalendarIcon, ThinkingIcon } from '../assets/img';
// reactstrap components
import {
    Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap";
import { Stack, TablePagination } from '@mui/material';
import profileService from 'services/profile.service';
import styled from 'styled-components';
import Notification from 'utils/notification';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { themes } from 'redux/reducers/quizSlice';
import moment from 'moment';
import GreenButton from 'components/UI/GreenButton';
import { useNavigate } from 'react-router-dom';
import CreateUpdateTheme from 'components/Quiz/CreateUpdateTheme';
import { decrementPageNumber } from 'redux/reducers/quizSlice';
import { incrementPageNumber } from 'redux/reducers/quizSlice';
import PaginationBox from 'components/UI/PaginationBox';
import Loader from 'components/UI/Loader';
import NoData from 'components/UI/NoData';

const ActionStyle = styled.div`
    display: flex;
    gap: 10px;
    .edit{
        cursor: pointer;
    }
    .delete{
        cursor: pointer;
    }
`
const FilterDiv = styled.div`
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
`
const SortFilterDiv = styled.div`
    display: flex;
    gap: 10px;
    select{
        height: 40px;
        background-color: #322e60;
        border-color: red;
        border-radius: 17px;
        width: 250px;
        color: white;
        text-indent: 15px;
        font-size: small;
    }
    > * {
        flex: 0 0 auto;
    }
`
function QuizThemes() {
    const [themeQuiz, setThemeQuiz] = React.useState()
    const [themeQuizId, setThemeQuizId] = React.useState()
    const [categories, setCategories] = React.useState([])
    const [category, setCategory] = React.useState()
    const [skip, setSkip] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    // Modal open state
    const [modal, setModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState(false);
    // Notification
    const [showNotif, setShowNotif] = React.useState(false)
    const [color, setColor] = React.useState()
    const [message, setMessage] = React.useState("")

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, quizThemes, quizCategories, pageNumber, error } = useSelector((state) => state.quiz, shallowEqual)

    const navigateDetails = (id) => {
        navigate(id);
    };

    // Toggle for Modal
    const toggle = (q) => {
        if (q !== undefined) {
            setThemeQuiz(q)
        } else {
            setThemeQuiz(undefined)
        }
        fetchThemeQuizzes()
        setModal(!modal)
    }

    const deleteToggle = () => {
        setDeleteModal(!deleteModal)
    }
    const decrementPage = () => {
        dispatch(decrementPageNumber());
    }
    const incrementPage = () => {
        dispatch(incrementPageNumber());
    }

    const fetchThemeQuizzes = async (c) => {
        const skip = pageNumber === 1 ? 0 : limit * pageNumber - limit
        setSkip(skip);
        let filter = { pageNumber, skip };
        if (c !== undefined && c !== "") {
            setCategory(c);
            filter.categoryId = c;
        }
        dispatch(themes(filter))
    }

    const fetchCategories = async () => {
        if (quizCategories.length === 0) {
            const allCategories = await profileService.getAllCategories()
            setCategories(allCategories.data.data);
        }
        else setCategories(quizCategories.data);
    }

    React.useEffect(() => {
        fetchThemeQuizzes(category);
    }, [])

    React.useEffect(() => {
        fetchCategories();
    }, [quizCategories])

    const deleteTheme = async (id) => {
        try {
            profileService.deleteQuizTheme(id).then((response) => {
                setThemeQuizId("");
                fetchThemeQuizzes();
                deleteToggle();
            })
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <>
            <div className="content">
                {showNotif && <Notification color={color} message={message} />}
                <Row>
                    <Col md="12">
                        <Card style={{
                            height: '730px',
                            overflowY: 'auto',
                        }}>
                            <CardHeader>
                                <CardTitle tag="h4"><img src={QuizThemeIcon} alt="Quizzes" style={{ marginRight: "8px", width: "25px", height: "27px" }} /> Quiz Themes</CardTitle>
                                <CardTitle>
                                    <FilterDiv>
                                        <SortFilterDiv>
                                            <h5>Filter by category:</h5>
                                            <select defaultValue="" onChange={(e) => { fetchThemeQuizzes(e.target.value); }} name="categoryFilter">
                                                <option value=""></option>
                                                {categories && categories.map((category) => {
                                                    return (
                                                        <option key={category._id} value={category._id}>{category.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </SortFilterDiv>
                                    </FilterDiv>
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="table-full-width table-responsive" >
                                    {loading ? (<div style={{ paddingTop: "100px" }}><Loader /></div>) :
                                        (quizThemes.data && quizThemes.data.length === 0) ?
                                            <NoData text={"No quiz themes!"} />
                                            :
                                            <Table>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Theme title</th>
                                                        <th>Availability</th>
                                                        <th>Category</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th className="text-center">N° Of Questions</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {quizThemes.data && quizThemes.data.map((theme, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td style={{ cursor: "pointer" }} onClick={() => { navigateDetails(theme._id) }}>{theme.title}</td>
                                                                <td>{theme.available ? <GreenButton text={"Available"} /> : <p style={{ color: "red" }}>Not Available</p>}</td>
                                                                <td>{theme.quizCategory.name}</td>
                                                                <td><img src={CalendarIcon} alt="calendar" /> {moment(theme.startDate).format('ll')}</td>
                                                                <td><img src={CalendarIcon} alt="calendar" /> {moment(theme.endDate).format('ll')}</td>
                                                                <td className="text-center">{theme.nbQuestions}</td>
                                                                <td>
                                                                    <ActionStyle>
                                                                        <div className='edit'><img src={EditIcon} alt="edit" onClick={() => { toggle(theme); }} /></div>
                                                                        <div className='delete'><img src={DeleteIcon} alt="delete" onClick={() => { deleteToggle(); setThemeQuizId(theme._id) }} /></div>
                                                                    </ActionStyle>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                        <PaginationBox currentPage={pageNumber} numberOfPages={Math.ceil(quizThemes.totalCount / limit)} totalNumber={quizThemes.totalCount} decrement={decrementPage} increment={incrementPage} />
                    </Col>
                </Row>
                <Modal isOpen={deleteModal} modalTransition={{ timeout: 300 }} backdropTransition={{ timeout: 1300 }} toggle={deleteToggle} >
                    <ModalHeader toggle={deleteToggle}>Delete Quiz Theme</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this quiz theme?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => deleteTheme(themeQuizId)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={deleteToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                {modal && <CreateUpdateTheme modal={modal} toggle={toggle} theme={themeQuiz} />}
            </div>
        </>
    )
}

export default QuizThemes;